import React, { SyntheticEvent, useCallback, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useNotifications } from "../hoc/notifications/withNotifications";
import { Notification as CSRNotification, NotificationTitle } from "../models/Notification";
import { isOfType } from "../utils/Helpers";
import { Snackbar, Alert, IconButton, Stack, Button, SnackbarCloseReason } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { RouteKey } from "../routes/Routes";
import "../styles/notifications.css";

interface NotificationSnackbarProps {
    notification: CSRNotification;
    onNotificationInteraction: (notificationId: string) => void;
}

export const NotificationSnackbar = React.memo((props: NotificationSnackbarProps) => {
    const notificationProps = useNotifications();
    const navigate = useNavigate();
    const notification = props.notification;
    const handleClose = useCallback(
        (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason | "userDismissed") => {
            if (reason === "userDismissed") {
                notificationProps.dismissNotification(props.notification.notification_id);
            }
            props.onNotificationInteraction(props.notification.notification_id);
        },
        [notificationProps, props],
    );
    const rejectedJobActions = React.useMemo(() => {
        const onReassign = (e: SyntheticEvent) => {
            e.stopPropagation();
            handleClose(e, "timeout");
            navigate(`${RouteKey.Home}?jobId=${notification.job_id}`);
            // Handle reassign action
        };
        const onAddForTomorrow = (e: SyntheticEvent) => {
            e.stopPropagation();
            handleClose(e, "timeout");
            notificationProps.updateJobDeliveryTime(notification.notification_id);
        };
        return (
            <div style={{ marginLeft: "2em" }}>
                <Button
                    variant="contained"
                    onClick={onReassign}
                    className="notification-action"
                    sx={{
                        minWidth: "fit-content",
                        height: "40px",
                        borderRadius: "100px",
                        gap: "8px",
                        color: "#FFFFFF",
                    }}>
                    Reassign Now
                </Button>
                <Button
                    variant="contained"
                    onClick={onAddForTomorrow}
                    className="notification-action"
                    style={{ marginLeft: "2em" }}
                    sx={{
                        minWidth: "fit-content",
                        height: "40px",
                        borderRadius: "100px",
                        gap: "8px",
                        color: "#FFFFFF",
                    }}>
                    Add for tomorrow
                </Button>
            </div>
        );
    }, [handleClose, navigate, notification.job_id, notification.notification_id, notificationProps]);

    return (
        <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
            autoHideDuration={5000}
            sx={{
                "& .MuiSnackbarContent-root": {
                    borderRadius: "12px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                },
                zIndex: 200,
            }}
            data-testid={`notification-snackbar-${notification.notification_id}`}>
            <Alert
                onClose={(e) => handleClose(e, "userDismissed")}
                variant="filled"
                severity={notification.title === NotificationTitle.RejectedJob ? "warning" : undefined}
                sx={{
                    borderRadius: "10px",
                    paddingTop: "10px",
                    paddingRight: "16px",
                    paddingBottom: "10px",
                    paddingLeft: "16px",
                    gap: "10px",
                    mixBlendMode: "luminosity",
                    color: "black",
                    backgroundColor: "#ECECEC",
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        sx={{
                            width: 34,
                            height: 34,
                            borderRadius: "27px",
                            gap: "10px",
                            padding: "5px",
                            background: "#FBFBFB",
                        }}
                        onClick={(e) => handleClose(e, "userDismissed")}>
                        <CloseIcon htmlColor="black" fontSize="small" />
                    </IconButton>
                }>
                <Stack width={"100%"}>
                    <Typography
                        fontWeight={"bolder"}
                        className="notification-card-title"
                        variant="h6"
                        color="black"
                        sx={{ flexGrow: 1 }}>
                        {notification.title}
                    </Typography>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography
                            fontWeight={"bolder"}
                            color="black"
                            className="notification-card-body"
                            variant="body2">
                            {notification.message}
                        </Typography>
                        {notification.title === NotificationTitle.RejectedJob ? rejectedJobActions : null}
                    </Stack>
                </Stack>
            </Alert>
        </Snackbar>
    );
});

export const NotificationHandler = React.memo(() => {
    //Public API that will echo messages sent to it back to the client
    const [socketUrl] = useState(process.env.REACT_APP_WEB_SOCKET_URL ?? "");
    const token = localStorage.getItem("accessToken");
    const role = localStorage.getItem("userRole");
    const notifications = useNotifications();
    const [notificationsList, setNotificationsList] = useState<CSRNotification[]>([]);
    const onNotificationInteraction = useCallback((id: string) => {
        setNotificationsList((prev) => prev.filter((notification) => notification.notification_id !== id));
    }, []);

    useWebSocket(
        `${socketUrl}?token=${token}`,
        {
            shouldReconnect: () => {
                // Will attempt to reconnect on all close events, such as server shutting down
                return true;
            },
            onMessage: (message) => {
                const notification = JSON.parse(message.data);
                if (notification && isOfType<CSRNotification>(notification, "title")) {
                    notifications.appendNotification(notification);
                    notifications.fetchUnreadNotificationsCount();
                    setNotificationsList((prev) => [...prev, notification]);
                }
            },
            reconnectAttempts: 3,
            share: true,
        },
        true,
    );

    return role !== "admin" ? null : (
        <>
            {notificationsList.map((notification) => (
                <NotificationSnackbar
                    key={notification.notification_id}
                    notification={notification}
                    onNotificationInteraction={onNotificationInteraction}
                />
            ))}
        </>
    );
});

NotificationHandler.displayName = "NotificationHandler";
