import React, { useCallback, useEffect, useRef } from "react";
import { Marker, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { Job } from "../../../models/Job";
import { createClusterCustomIcon, getGrayIcon } from "./MapIcons";

type Props = {
    jobs: Job[];
    onJobClick: (jobId: string) => void;
};

export const ClusterGroupWithTooltips = React.memo(({ jobs, onJobClick }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clusterRef = useRef<any>(null);
    const activeTooltipRef = useRef<L.Tooltip | null>(null);
    const map = useMap();
    const handleMarkerMouseOver = useCallback(
        (e: L.LeafletMouseEvent, job: Job) => {
            const marker = e.target as L.Marker;

            const title = job.description.length > 20 ? job.description.substring(0, 20) + "..." : job.description;
            const li = `<li data-job-id="${job.job_id}" style="cursor: pointer; margin-bottom: 4px;">
                    <strong>${title}</strong>
                </li>`;
            const tooltipHtml = `
                    <div id="jobListPopup" style="max-height: 150px; overflow-y: auto;" class="job-tooltip-content">
                        <ol type="1">
                        ${li}
                        </ol>
                    </div>
                    `;

            if (activeTooltipRef.current) {
                map.closeTooltip(activeTooltipRef.current);
                activeTooltipRef.current = null;
            }

            const tooltip = L.tooltip({
                permanent: true,
                interactive: true,
                direction: "right",
                offset: [30, 0],
                className: "cluster-tooltip",
            })
                .setLatLng(marker.getLatLng())
                .setContent(tooltipHtml);

            activeTooltipRef.current = tooltip;
            marker.bindTooltip(tooltip).openTooltip();
        },
        [map],
    );

    useEffect(() => {
        const clusterLayer = clusterRef.current;

        if (!clusterLayer) return;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onClusterMouseOver = (e: any) => {
            const cluster = e.layer;
            const childMarkers = cluster.getAllChildMarkers();

            const jobList = childMarkers
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((marker: any) => {
                    const spanChild = marker.options.children;
                    const id = spanChild.props.className.toString();
                    const job = jobs.find((job) => {
                        return job.job_id === id;
                    });
                    if (!job) {
                        return "";
                    }
                    const title =
                        job.description.length > 20 ? job.description.substring(0, 20) + "..." : job.description;
                    return `<li data-job-id="${id}" style="cursor: pointer; margin-bottom: 4px;">
                    <strong>${title}</strong>
                  </li>`;
                })
                .join("");

            const tooltipHtml = `
        <div id="jobListPopup" style="max-height: 150px; overflow-y: auto;">
          <ol type="1">
            ${jobList}
          </ol>
        </div>
      `;

            // Close previous Tooltip
            if (activeTooltipRef.current) {
                map.closeTooltip(activeTooltipRef.current);
                activeTooltipRef.current = null;
            }

            const toolTip = L.tooltip({
                permanent: true,
                interactive: true,
                direction: "right",
                offset: [30, 0],
            })
                .setLatLng(cluster.getLatLng())
                .setContent(tooltipHtml);
            cluster.bindTooltip(toolTip).openTooltip();
            activeTooltipRef.current = toolTip;
        };
        const closeActiveTooltip = () => {
            if (activeTooltipRef.current) {
                map.closeTooltip(activeTooltipRef.current);
                activeTooltipRef.current = null;
            }
        };

        const onTooltipClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const jobElement = target.closest("li[data-job-id]");
            if (jobElement) {
                const jobId = jobElement.getAttribute("data-job-id");
                if (jobId) {
                    onJobClick(jobId);
                }
            } else {
                const targetClass = target.className;
                if (!targetClass || !targetClass.includes) {
                    return closeActiveTooltip();
                }
                if (targetClass.includes("leaflet-pane leaflet-tooltip-pane")) {
                    const lastChild = target.children[target.children.length - 1];
                    const li = lastChild?.querySelector("li[data-job-id]");
                    if (li) {
                        const jobId = li.getAttribute("data-job-id");
                        if (jobId) {
                            onJobClick(jobId);
                        }
                    }
                }
            }
            closeActiveTooltip();
        };

        // Attach events
        map.getContainer().addEventListener("click", onTooltipClick);
        map.on("movestart zoomstart", closeActiveTooltip);
        clusterLayer.on("clustermouseover", onClusterMouseOver);

        return () => {
            map.getContainer().removeEventListener("click", onTooltipClick);
            map.off("movestart zoomstart", closeActiveTooltip);
            clusterLayer.off("clustermouseover", onClusterMouseOver);
        };
    }, [jobs, map, onJobClick]);

    return (
        <MarkerClusterGroup
            chunkedLoading
            iconCreateFunction={createClusterCustomIcon}
            showCoverageOnHover={false}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={clusterRef as any}>
            {jobs
                .filter((job) => job.latitude && job.longitude)
                .map((job) => (
                    <Marker
                        key={job.job_id}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        position={[job.latitude!, job.longitude!]}
                        icon={getGrayIcon(1)}
                        eventHandlers={{
                            mouseover: (e) => handleMarkerMouseOver(e, job),
                            click: () => {
                                onJobClick(job.job_id);
                            },
                        }}>
                        <span className={job.job_id} style={{ display: "none" }}>
                            {job.job_id}
                        </span>
                    </Marker>
                ))}
        </MarkerClusterGroup>
    );
});
