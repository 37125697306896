import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";

// Material UI Icons
import RoomIcon from "@mui/icons-material/Room";
import CloseIcon from "@mui/icons-material/Close";

// TODO: Type definitions
/*
interface EditWorkerModalProps {
    worker?: unknown;
    onClose: () => void;
    onSubmitEdits: (id: string, worker: unknown, isEdit: boolean) => Promise<void>;
}
*/

export const EditWorkerModal = React.memo(({ worker, onClose, onSubmitEdits }) => {
    const cities = ["Denver", "Las Vegas", "Phoenix"];
    const [formName, setName] = useState(worker?.name || "");
    const [formContactInfo, setContactInfo] = useState(worker?.contact_info?.trim() || "");
    const [formAddress, setAddress] = useState(worker?.address || "");
    const [formCity, setCity] = useState(worker?.city || "");
    const [formState, setState] = useState(worker?.state || "");
    const [formZipCode, setZipCode] = useState(worker?.zip_code || "");
    const [formWorkLocation, setWorkLocation] = useState(worker?.warehouse || "");
    const [formNotes, setNotes] = useState(worker?.notes || "");
    /**
     * Modal status object
     * @typedef {Object} ModalStatus
     * @property {string} status - The status of the modal ("success" | "info" | "warning" | "error")
     * @property {string} message - The message to display in the modal
     * @property {object} errorControls - The error controls to display in the modal
     */
    const [modalStatus, setModalStatus] = useState(null);
    const isEdit = !!worker;

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();

            // Regular expressions for form validation
            const usPhoneRegex = /^\(?([0-9]{3})\)?[-.●\s]?([0-9]{3})[-.●\s]?([0-9]{4})$/;
            const zipCodeRegex = /^\d{5}(-\d{4})?$/;
            const cityStateRegex = /^[a-zA-Z\s-]+$/; // Allows letters, spaces, and hyphens
            const usAddressRegex = /^\d+\s[a-zA-Z0-9\s.,'-/#]+$/; // US address regex

            // Validate the form fields
            if (!formName || !formContactInfo || !formWorkLocation) {
                setModalStatus({
                    status: "error",
                    message: "Please fill in Name, Phone Number, and Work Location fields.",
                    errorControls: {
                        name: !formName,
                        contactInfo: !formContactInfo,
                        workLocation: !formWorkLocation,
                    },
                });
                return;
            }

            // Validate phone number
            if (!usPhoneRegex.test(formContactInfo)) {
                setModalStatus({
                    status: "error",
                    message: "Invalid phone number. Please enter a valid US phone number of the format (123) 456-7890.",
                    errorControls: {
                        contactInfo: true,
                    },
                });
                return;
            }

            // Validate address
            if (formAddress && !usAddressRegex.test(formAddress)) {
                // TODO: this needs to be updated to a more robust address validation, as this regex is not a truism for all addresses
                // Addresses can start without numbers
                setModalStatus({
                    status: "error",
                    message:
                        "Invalid address. The address must start with a house/building number, followed by a street name (e.g., 123 Main St). Avoid special characters like ! or @.",
                    errorControls: {
                        address: true,
                    },
                });
                return;
            }

            // Validate city
            if (formCity && !cityStateRegex.test(formCity)) {
                setModalStatus({
                    status: "error",
                    message: "Invalid city name. Please enter a valid city.",
                    errorControls: {
                        city: true,
                    },
                });
                return;
            }

            // Validate state
            if (formState && !cityStateRegex.test(formState)) {
                setModalStatus({
                    status: "error",
                    message: "Invalid state name. Please enter a valid state.",
                    errorControls: {
                        state: true,
                    },
                });
                return;
            }

            // Validate ZIP code
            if (formZipCode && !zipCodeRegex.test(formZipCode)) {
                setModalStatus({
                    status: "error",
                    message: "Invalid ZIP code. Please enter a valid US ZIP code.",
                    errorControls: {
                        zipCode: true,
                    },
                });
                return;
            }

            // Clear previous error messages
            setModalStatus(null);

            // Create the data object
            const formData = {
                name: formName,
                contact_info: formContactInfo,
                address: formAddress,
                city: formCity,
                state: formState,
                zip_code: formZipCode,
                warehouse: formWorkLocation,
                notes: formNotes,
                archived: false,
            };

            console.log(`${isEdit ? "Updating" : "Adding"} Worker:`, formData);
            onSubmitEdits(isEdit && worker ? worker.worker_id : null, formData, isEdit).catch((error) => {
                console.error(`Failed to ${isEdit ? "update" : "add"} worker:`, error);
                setModalStatus({
                    status: "error",
                    message: `Failed to ${isEdit ? "update" : "add"} the worker. Please try again.`,
                });
            });
        },
        [
            formName,
            formContactInfo,
            formWorkLocation,
            formAddress,
            formCity,
            formState,
            formZipCode,
            formNotes,
            isEdit,
            onSubmitEdits,
            worker,
        ],
    );

    return (
        <Modal open onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "75%",
                    bgcolor: "background.paper",
                    background: "#E5E5EA",
                    borderRadius: "20px",
                    boxShadow: 24,
                    padding: "30px 50px",
                }}>
                {/* Header Area */}
                <Grid container justifyContent="space-between" alignItems="flex-start" sx={{ mt: 2 }}>
                    <Typography id="modal-modal-title" sx={{ fontWeight: "bold" }} variant="h4" component="h2" mb={5}>
                        {isEdit ? "Edit Worker" : "Add New Worker"}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ fontSize: "0.5rem", backgroundColor: "white" }}>
                        <CloseIcon sx={{ fontSize: "1.5rem" }} />
                    </IconButton>
                </Grid>

                {/* Display Error Message */}
                {modalStatus && (
                    <Alert severity={modalStatus.status} sx={{ my: 2 }}>
                        {modalStatus.message}
                    </Alert>
                )}

                {/* Form Area */}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Left Section: Form Fields */}
                        <Grid size={8}>
                            <Grid container spacing={2}>
                                {/* Full Name and Phone Number */}
                                <Grid size={6}>
                                    <TextField
                                        fullWidth
                                        label="Full Name"
                                        variant="filled"
                                        value={formName}
                                        error={modalStatus?.errorControls?.name}
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Type Here"
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                backgroundColor: "white",
                                                "&:hover": { backgroundColor: "white" },
                                                "&.Mui-focused": { backgroundColor: "white" },
                                            },
                                            mb: 3,
                                        }}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        variant="filled"
                                        value={formContactInfo}
                                        error={modalStatus?.errorControls?.contactInfo}
                                        required
                                        onChange={(e) => setContactInfo(e.target.value ? e.target.value.trim() : "")}
                                        type="tel"
                                        placeholder="Type Here"
                                        helperText="Format: (123) 456-7890"
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                backgroundColor: "white",
                                                "&:hover": { backgroundColor: "white" },
                                                "&.Mui-focused": { backgroundColor: "white" },
                                            },
                                            mb: 3,
                                        }}
                                    />
                                </Grid>

                                {/* Address */}
                                <Grid size={12}>
                                    <TextField
                                        fullWidth
                                        label="Address"
                                        variant="filled"
                                        value={formAddress}
                                        onChange={(e) => setAddress(e.target.value)}
                                        error={modalStatus?.errorControls?.address}
                                        placeholder="Type Here"
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                backgroundColor: "white",
                                                "&:hover": { backgroundColor: "white" },
                                                "&.Mui-focused": { backgroundColor: "white" },
                                            },
                                            mb: 3,
                                        }}
                                    />
                                </Grid>

                                {/* City, State, and Zip Code */}
                                <Grid size={4}>
                                    <TextField
                                        fullWidth
                                        label="City"
                                        variant="filled"
                                        value={formCity}
                                        onChange={(e) => setCity(e.target.value)}
                                        error={modalStatus?.errorControls?.city}
                                        placeholder="Type Here"
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                backgroundColor: "white",
                                                "&:hover": { backgroundColor: "white" },
                                                "&.Mui-focused": { backgroundColor: "white" },
                                            },
                                            mb: 3,
                                        }}
                                    />
                                </Grid>
                                <Grid size={4}>
                                    <TextField
                                        fullWidth
                                        label="State"
                                        variant="filled"
                                        value={formState}
                                        error={modalStatus?.errorControls?.state}
                                        onChange={(e) => setState(e.target.value)}
                                        placeholder="Type Here"
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                backgroundColor: "white",
                                                "&:hover": { backgroundColor: "white" },
                                                "&.Mui-focused": { backgroundColor: "white" },
                                            },
                                            mb: 3,
                                        }}
                                    />
                                </Grid>
                                <Grid size={4}>
                                    <TextField
                                        fullWidth
                                        label="Zip Code"
                                        variant="filled"
                                        value={formZipCode}
                                        error={modalStatus?.errorControls?.zipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                        placeholder="Type Here"
                                        sx={{
                                            "& .MuiFilledInput-root": {
                                                backgroundColor: "white",
                                                "&:hover": { backgroundColor: "white" },
                                                "&.Mui-focused": { backgroundColor: "white" },
                                            },
                                            mb: 3,
                                        }}
                                    />
                                </Grid>

                                {/* Work Location */}
                                <Grid size={12}>
                                    <FormControl fullWidth required error={modalStatus?.errorControls?.workLocation}>
                                        <InputLabel
                                            id="warehouse-label"
                                            color="primary"
                                            sx={{
                                                transform: formWorkLocation
                                                    ? "translate(0, -20px) scale(0.75)"
                                                    : "translate(10px, 15px) scale(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}>
                                            <RoomIcon /> Work Location
                                        </InputLabel>
                                        <Select
                                            labelId="warehouse-label"
                                            id="warehouse"
                                            value={formWorkLocation}
                                            onChange={(e) => setWorkLocation(e.target.value)}
                                            sx={{ backgroundColor: "#1D1B2014" }}>
                                            {cities.map((city, index) => (
                                                <MenuItem key={index} value={city}>
                                                    {city}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Right Section: Notes */}
                        <Grid size={4}>
                            <TextField
                                fullWidth
                                label="Notes"
                                multiline
                                rows={13}
                                variant="filled"
                                value={formNotes}
                                onChange={(e) => setNotes(e.target.value)}
                                placeholder="Type Here"
                                helperText="Max 2000 characters"
                                slotProps={{ htmlInput: { maxLength: 2000 } }}
                                sx={{
                                    "& .MuiFilledInput-root": {
                                        backgroundColor: "white",
                                        "&:hover": { backgroundColor: "white" },
                                        "&.Mui-focused": { backgroundColor: "white" },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Action Buttons */}
                    <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
                        <button
                            style={{
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "50px",
                                padding: "10px 20px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                cursor: "pointer",
                                fontWeight: "500",
                            }}>
                            {isEdit ? "Update Worker" : "Add Worker"}
                        </button>

                        <button
                            style={{
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "50px",
                                padding: "10px 20px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                cursor: "pointer",
                                fontWeight: "500",
                            }}
                            onClick={onClose}>
                            Cancel
                        </button>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
});

EditWorkerModal.displayName = "EditWorkerModal";
