import { DateTime } from "luxon";
import { CSRService } from "./CSRService";
import { Worker } from "../models/Worker";

/**
 * Service for manipulation workers from the API
 */
export class WorkerService extends CSRService {
    public constructor() {
        super();
    }

    /**
     * Fetches workers from the API
     * @param city - The city to filter workers by
     * @param date - The date to filter workers by
     * @returns A list of jobs
     */
    public fetchWorkersAsync = async (city: string, date?: string): Promise<Worker[]> => {
        const params = new URLSearchParams();
        if (city) {
            params.append("warehouse", city);
        }
        if (date) {
            params.append("date_requested", date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd") : "");
        }

        // Fetch job data
        const workerResponse = await WorkerService.axiosInstance.get<Worker[]>(`/api/workers?${params.toString()}`);
        return workerResponse.data ?? [];
    };
}

export const workerService = new WorkerService();
