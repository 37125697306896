import React from "react";
import "../../styles/workers.css";
import { withProtectedRoute } from "../../components/ProtectedRoute";
import CustomSnackbar from "../../components/SnackbarDialog";
import { Container } from "@mui/material";
import { WorkersFilterSelectors } from "./components/WorkersFilterSelectors";
import axiosInstance from "../../utils/api";
import { WorkersTable } from "./components/WorkersTable";
import { WorkerDetailsSidePanel } from "./components/WorkerDetailsSidePanel";
import { EditWorkerModal } from "./components/EditWorkerModal";
import { fetchSystemMetadata } from "../../utils/apiHelpers";

// TODO: Type definitions for local component state
/*
interface IOwnState {
    showAlert: boolean;
    alertContent: {
        title: string;
        message: string;
        severity: "success" | "info" | "warning" | "error";
    };
    isLoadingWorkers: boolean;
    workers: unknown[];
    fullPageError?: unknown;
    selectedWorker: unknown | null;
    editWorkerModal: {worker: unknown} | null;
    selectedCity: string;
}
    */

export class WorkersPageComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
            isLoadingWorkers: false,
            workers: [],
            fullPageError: undefined,
            selectedWorker: null,
            selectedCity: "Denver",
            systemLastUpdated: null,
        };
    }

    componentDidMount() {
        this._fetchWorkers("Denver");

        this._fetchSystemMetadata();
    }

    render() {
        return (
            <>
                <CustomSnackbar
                    title={this.state.alertContent.title}
                    message={this.state.alertContent.message}
                    severity={this.state.alertContent.severity}
                    isOpen={this.state.showAlert}
                    onClose={this._hideAlert}
                />
                <Container sx={{ pl: 0 }} maxWidth={false} disableGutters>
                    <WorkersFilterSelectors
                        onFilterChange={this._fetchWorkers}
                        lastUpdated={this.state.systemLastUpdated}
                    />
                    <Container sx={{ mb: 5, background: "white", py: 4, pl: "100px" }} maxWidth={false} disableGutters>
                        <WorkersTable
                            workers={this.state.workers}
                            isLoading={this.state.isLoadingWorkers}
                            onOpenEditWorkerModal={this._editOrCreateWorker}
                            onWorkerSelected={(worker) => this.setState({ selectedWorker: worker })}
                        />
                        {this.state.selectedWorker ? (
                            <WorkerDetailsSidePanel
                                worker={this.state.selectedWorker}
                                closeDetailView={() => this.setState({ selectedWorker: null })}
                                onEditWorker={this._editOrCreateWorker}
                                handleDelete={this._handleDelete}
                            />
                        ) : null}
                    </Container>
                </Container>
                {this.state.editWorkerModal ? (
                    <EditWorkerModal
                        worker={this.state.editWorkerModal.worker}
                        onClose={() => this.setState({ editWorkerModal: null })}
                        onSubmitEdits={this._saveWorker}
                    />
                ) : null}
            </>
        );
    }

    _editOrCreateWorker = (workerId, isEdit = false) => {
        if (!isEdit) {
            this.setState({ editWorkerModal: { worker: null } });
        } else {
            const worker = this.state.workers.find((w) => w.worker_id === workerId);
            if (worker) {
                this.setState({ editWorkerModal: { worker } });
            }
        }
    };

    _fetchSystemMetadata = async () => {
        const systemLastUpdated = await fetchSystemMetadata();
        if (systemLastUpdated !== null) {
            this.setState({ systemLastUpdated });
        }
    };

    _fetchWorkers = async (city) => {
        this.setState({ isLoadingWorkers: true, selectedCity: city });
        try {
            // Fetch workers data
            let workersResponse = await axiosInstance.get(
                `${process.env.REACT_APP_API_URL}/api/workers?warehouse=${city}`,
            );
            console.log("Workers Response:", workersResponse.data);
            this.setState({ workers: workersResponse.data });
        } catch (error) {
            if (error && error.message) {
                this.setState({ fullPageError: error.message });
            }
        } finally {
            this.setState({ isLoadingWorkers: false });
        }
    };

    _hideAlert = () => {
        this.setState({
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
        });
    };

    _handleDelete = async (worker, archive = false) => {
        try {
            // Send DELETE request to the API
            await axiosInstance.delete(
                `${process.env.REACT_APP_API_URL}/api/workers/${worker.worker_id}?archive=${archive}`,
            );

            this.setState({
                workers: this.state.workers.filter((w) => w.worker_id !== worker.worker_id),
                selectedWorker: null,
                showAlert: true,
                alertContent: {
                    title: "Worker Deleted",
                    message: `Worker ${worker.name} has been successfully deleted.`,
                    severity: "success",
                },
            });
        } catch (error) {
            console.error("Error deleting worker:", error);
            this.setState({
                workers: this.state.workers.filter((w) => w.worker_id !== worker.worker_id),
                selectedWorker: null,
                showAlert: true,
                alertContent: {
                    title: "Error",
                    message: `There was an error deleting the worker ${worker.name}. Please try again: ${
                        error ? error.message : ""
                    }`,
                    severity: "error",
                },
            });
        }
    };

    _saveWorker = async (id, data, isEdit) => {
        try {
            if (isEdit) {
                await axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/workers/${id}`, data);
                this.setState({
                    showAlert: true,
                    alertContent: {
                        title: "Worker Updated",
                        message: `Worker ${data.name} has been successfully updated.`,
                        severity: "success",
                    },
                    editWorkerModal: null,
                });

                // Update the worker in the state if the warehouse matches the active city
                if (data.warehouse === this.state.selectedCity) {
                    // TODO: Replace this with the data returned from the API as that should be the source of truth
                    this.setState({
                        workers: this.state.workers.map((worker) =>
                            worker.worker_id === id ? { ...worker, ...data } : worker,
                        ),
                    });
                } else {
                    // Remove the worker from the table data state if the warehouse doesn't match the active city
                    this.setState({
                        workers: this.state.workers.filter((worker) => worker.worker_id !== id),
                    });
                }
            } else {
                const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/workers`, data);
                const newWorker = {
                    ...data,
                    worker_id: response.data.worker_id,
                    total_paid: 0,
                    pending_payment_total: 0,
                };
                this.setState({
                    editWorkerModal: null,
                    showAlert: true,
                    alertContent: {
                        title: "Worker Added",
                        message: `Worker ${data.name} has been successfully added.`,
                        severity: "success",
                    },
                });

                // Add the new worker to the table data state if the warehouse matches the active city
                if (data.warehouse === this.state.selectedCity) {
                    this.setState({
                        workers: this.state.workers.concat([newWorker]),
                    });
                }
            }
        } catch (error) {
            console.error("Error saving worker:", error);
            throw error;
        }
    };
}

export const WorkersPage = withProtectedRoute(WorkersPageComponent);
