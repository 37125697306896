import React, { useCallback, useRef, useEffect } from "react";
import JobDetails from "./JobDetails";
import WorkIcon from "@mui/icons-material/Work";
import RoomIcon from "@mui/icons-material/Room";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EngineeringIcon from "@mui/icons-material/Engineering";
import NumbersIcon from "@mui/icons-material/Numbers";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Job } from "../../../models/Job";
import { Worker } from "../../../models/Worker";
import { SelectChangeEvent } from "@mui/material";

interface JobDetailViewSidePanelProps {
    job: Job;
    jobs: Job[];
    onJobsUpdated: (updatedTableData: Job[]) => void;
    onSaveWorkerData: (job_id: string, worker_id: string | null, worker_name: string, job_order_number: string) => void;
    closeDetailView: () => void;
    workers: Worker[];
    isAssignmentPage?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSaveAssigmentStatus?: (formData: any) => void;
    onRejectionAssignmentRequest?: (row: Job) => void;
    style?: React.CSSProperties;
}
/**
 * JobDetailViewSidePanel component
 * @param {JobDetailViewSidePanelProps} props
 * @param {object} props.job - Job details
 * @param {object[]} props.jobs - List of jobs
 * @param {function} props.onSaveWorkerData - Callback to save worker data
 * @param {function} props.onJobsUpdated - Callback to update jobs
 * @param {function} props.closeDetailView - Callback to close detail view
 * @param {object[]} props.workers - List of workers
 * @returns {JSX.Element}
 */
export const JobDetailViewSidePanel = React.memo(
    ({
        job,
        jobs,
        workers,
        onSaveWorkerData,
        onJobsUpdated,
        closeDetailView,
        onSaveAssigmentStatus,
        onRejectionAssignmentRequest,
        isAssignmentPage = false,
        ...props
    }: JobDetailViewSidePanelProps) => {
        const detailViewRef = useRef<HTMLDivElement>(null);
        const userRole = localStorage.getItem("userRole");

        const handleWorkerChange = useCallback(
            (e: SelectChangeEvent) => {
                const worker_id = e.target.value === "None" ? null : e.target.value;
                const worker_search_name_id = worker_id ? worker_id : job.worker_id;
                const worker = workers.find((worker) => worker.worker_id === worker_search_name_id);
                const worker_name = worker ? worker.name : "N/A";

                // Update tableData
                const updatedTableData = jobs.map(
                    (r) => (r.job_order_number === job.job_order_number ? { ...r, worker_id: worker_id } : r), // Keep other rows unchanged
                );

                onJobsUpdated(updatedTableData);

                // Update selected job in the detail view
                job.worker_id = worker_id;
                job.worker_name = worker_name;

                onSaveWorkerData(job.job_id, worker_id, worker_name, job.job_order_number);
            },
            [workers, jobs, onJobsUpdated, job, onSaveWorkerData],
        );
        const handleClickOutside = useCallback(
            (event: MouseEvent) => {
                if (detailViewRef.current && !detailViewRef.current.contains(event.target as Node)) {
                    closeDetailView();
                }
            },
            [closeDetailView],
        );
        useEffect(() => {
            if (job) {
                document.addEventListener("mousedown", handleClickOutside);
            } else {
                document.removeEventListener("mousedown", handleClickOutside);
            }
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [handleClickOutside, job]);

        const handleAssignmentChange = useCallback(
            (e: SelectChangeEvent) => {
                const formData = {
                    status: e.target.value,
                    worker_id: job.worker_id,
                    rejection_reason: null,
                    job_id: job.job_id,
                    jobOrder: job.job_order_number,
                    jobRequestAssignmentID: job.assignment?.assignment_id,
                };
                if (onSaveAssigmentStatus) {
                    onSaveAssigmentStatus(formData);
                }

                // TODO: This needs to be updated to fit schema
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (job as any).assignment = {
                    status: formData.status,
                    rejection_reason: formData.rejection_reason,
                };
            },
            [job, onSaveAssigmentStatus],
        );

        const handleSelectAssignmentChange = useCallback(
            (e: SelectChangeEvent, row: Job) => {
                if (e.target.value === "Rejected" && onRejectionAssignmentRequest) {
                    onRejectionAssignmentRequest(row);
                } else if (e.target.value === "Accepted") {
                    handleAssignmentChange(e);
                } else {
                    // TODO 2: Add logic to delete assignment object on draft status
                }
            },
            [handleAssignmentChange, onRejectionAssignmentRequest],
        );

        return (
            <div className="detail-view" ref={detailViewRef} style={props.style}>
                <div className="d-flex justify-content-end mb-4">
                    <button
                        onClick={closeDetailView}
                        className="btn btn-secondary btn-close"
                        aria-label="Close"></button>
                </div>

                <div className="container">
                    <div className="container-fluid px-5">
                        {/* First Row: Job Order and Worker Selection */}
                        <div className="row d-flex align-items-stretch">
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <NumbersIcon sx={{ color: "#111111B2" }} /> Job Order
                                    </p>
                                    <p className="ps-2">{job.job_order_number}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <div className="d-flex align-items-center">
                                        <EngineeringIcon sx={{ color: "#111111B2", marginRight: 1 }} />

                                        {!isAssignmentPage ? (
                                            <select
                                                className="form-select ps-3"
                                                value={job.worker_id || ""}
                                                // disable if the userRole is not admin
                                                disabled={userRole !== "admin"}
                                                onChange={(e) => handleWorkerChange(e as unknown as SelectChangeEvent)}
                                                style={{
                                                    backgroundColor: "#1D1B2014",
                                                    border: "none",
                                                    color: "#111111B2",
                                                    height: "45px",
                                                    padding: "8px 10px",
                                                }}>
                                                <option value="None">Select a worker</option>
                                                {workers.map((worker, index) => (
                                                    <option key={index} value={worker.worker_id}>
                                                        {worker.name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            // Show if worker exists
                                            <p>{job.worker_name || "N/A"}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Second Row: Date Requested and Place Name */}
                        <div className="row d-flex align-items-stretch">
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <CalendarMonthIcon sx={{ color: "#111111B2" }} /> Date Requested
                                    </p>
                                    <p className="ps-2">{job.date_requested}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <LocalOfferIcon sx={{ color: "#111111B2" }} /> Place Name
                                    </p>
                                    <p className="ps-2">{job.place_name}</p>
                                </div>
                            </div>
                        </div>

                        {/* Third Row: Job Description and Address */}
                        <div className="row d-flex align-items-stretch">
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <WorkIcon sx={{ color: "#111111B2" }} /> Job Description
                                    </p>
                                    <p className="ps-2">{job.description}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <RoomIcon sx={{ color: "#111111B2" }} /> Address
                                    </p>
                                    <p className="ps-2">{job.address}</p>
                                </div>
                            </div>
                        </div>

                        {/* Fourth Row: Zip Code and City */}
                        <div className="row d-flex align-items-stretch">
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <svg
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.436 5.40972C2 5.90572 2 6.75772 2 8.45972V17.8537C2 19.0597 2 19.6627 2.315 20.1167C2.629 20.5707 3.179 20.7617 4.279 21.1427L5.573 21.5917C6.274 21.8347 6.813 22.0217 7.266 22.1417C7.562 22.2207 7.833 21.9887 7.833 21.6827V6.53272C7.82954 6.41127 7.78597 6.29439 7.70908 6.20032C7.6322 6.10625 7.52633 6.04028 7.408 6.01272C7.019 5.91372 6.551 5.75172 5.91 5.52972C4.357 4.99072 3.58 4.72172 2.99 4.99472C2.77883 5.09359 2.59025 5.23485 2.436 5.40972ZM12.62 3.74372L11.084 4.80872C10.529 5.19372 10.121 5.47672 9.774 5.67772C9.69257 5.72378 9.6245 5.79021 9.57646 5.8705C9.52843 5.95079 9.50208 6.04218 9.5 6.13572V21.1827C9.5 21.5527 9.884 21.7847 10.196 21.5847C10.531 21.3707 10.915 21.1047 11.38 20.7827L12.916 19.7177C13.471 19.3327 13.879 19.0497 14.226 18.8487C14.3074 18.8027 14.3755 18.7362 14.4235 18.6559C14.4716 18.5756 14.4979 18.4843 14.5 18.3907V3.34272C14.5 2.97172 14.116 2.74072 13.804 2.93972C13.469 3.15472 13.085 3.42072 12.62 3.74372ZM19.72 3.38272L18.427 2.93472C17.726 2.69172 17.187 2.50472 16.734 2.38472C16.438 2.30572 16.167 2.53772 16.167 2.84372V17.9937C16.1705 18.1152 16.214 18.232 16.2909 18.3261C16.3678 18.4202 16.4737 18.4862 16.592 18.5137C16.981 18.6127 17.449 18.7737 18.09 18.9967C19.643 19.5357 20.42 19.8047 21.01 19.5317C21.2212 19.4328 21.4097 19.2916 21.564 19.1167C22 18.6207 22 17.7687 22 16.0667V6.67272C22 5.46672 22 4.86272 21.685 4.40972C21.371 3.95572 20.821 3.76472 19.721 3.38372"
                                                fill="#111111"
                                                fillOpacity="0.7"
                                            />
                                        </svg>{" "}
                                        Zip Code
                                    </p>
                                    <p className="ps-2">{job.zip_code}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fw-bold mb-5">
                                    <p>
                                        <svg
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.2192 6.66289H17.5968V4.03889C17.5968 3.88613 17.5361 3.73962 17.428 3.6316C17.32 3.52358 17.1735 3.46289 17.0208 3.46289H16.5744C16.4216 3.46289 16.2751 3.52358 16.1671 3.6316C16.059 3.73962 15.9984 3.88613 15.9984 4.03889V6.66289H13.376C13.2232 6.66289 13.0767 6.72358 12.9687 6.8316C12.8606 6.93962 12.8 7.08613 12.8 7.23889V16.2597H6.97755C6.65755 16.2597 6.39675 16.5205 6.39675 16.8405V20.4805C6.39675 20.8005 6.65755 21.0597 6.97755 21.0597H20.2176C20.5375 21.0597 20.7936 20.8005 20.7936 20.4821V7.23889C20.7936 7.08613 20.7329 6.93962 20.6248 6.8316C20.5168 6.72358 20.3703 6.66289 20.2176 6.66289M9.59675 19.4597H7.99675V17.8597H9.59675V19.4597ZM12.7968 19.4597H11.1968V17.8597H12.7968V19.4597ZM15.9968 19.4597H14.3968V17.8597H15.9968V19.4597ZM15.9968 16.2597H14.3968V14.6597H15.9968V16.2597ZM15.9968 13.0597H14.3968V11.4597H15.9968V13.0597ZM15.9968 9.85969H14.3968V8.25969H15.9968V9.85969ZM19.1968 19.4597H17.5968V17.8597H19.1968V19.4597ZM19.1968 16.2597H17.5968V14.6597H19.1968V16.2597ZM19.1968 13.0597H17.5968V11.4597H19.1968V13.0597ZM19.1968 9.85969H17.5968V8.25969H19.1968V9.85969ZM11.1968 4.04049C11.197 3.96471 11.1822 3.88964 11.1534 3.81957C11.1245 3.74951 11.0821 3.68582 11.0286 3.63216C10.9751 3.57851 10.9115 3.53594 10.8415 3.50689C10.7716 3.47784 10.6965 3.46289 10.6208 3.46289H6.97595C6.82319 3.46289 6.67668 3.52358 6.56866 3.6316C6.46064 3.73962 6.39995 3.88613 6.39995 4.03889V6.66289H3.77595C3.62319 6.66289 3.47668 6.72358 3.36866 6.8316C3.26064 6.93962 3.19995 7.08613 3.19995 7.23889V20.4981C3.19995 20.8085 3.45115 21.0581 3.75995 21.0581H4.79995V14.6629H11.1984L11.1968 4.04049ZM6.39995 13.0597H4.79995V11.4597H6.39995V13.0597ZM6.39995 9.85969H4.79995V8.25969H6.39995V9.85969ZM9.59995 13.0597H7.99995V11.4597H9.59995V13.0597ZM9.59995 9.85969H7.99995V8.25969H9.59995V9.85969ZM9.59995 6.65969H7.99995V5.06289H9.59995V6.66289V6.65969Z"
                                                fill="#111111"
                                                fillOpacity="0.7"
                                            />
                                        </svg>{" "}
                                        City
                                    </p>
                                    <p className="ps-2">{job.city}</p>
                                </div>
                            </div>
                        </div>

                        {/* Fifth Row: Suite */}
                        <div className="row d-flex align-items-stretch">
                            <div className="col-md-6">
                                <div className="fw-bold">
                                    <p>
                                        <MeetingRoomIcon sx={{ color: "#111111B2" }} /> Suite
                                    </p>
                                    <p className="ps-2">{job.suite}</p>
                                </div>
                            </div>

                            {/* Assignment Status */}
                            <div className="col-md-6">
                                {isAssignmentPage ? (
                                    <div className="d-flex align-items-center">
                                        <FactCheckOutlinedIcon sx={{ color: "#111111B2", marginRight: 1 }} />

                                        <select
                                            className="form-select ps-3"
                                            value={job.assignment?.status || "None"}
                                            onChange={(e) =>
                                                handleSelectAssignmentChange(e as unknown as SelectChangeEvent, job)
                                            }
                                            style={{
                                                backgroundColor: "#1D1B2014",
                                                border: "none",
                                                color: "#111111B2",
                                                height: "45px",
                                                padding: "8px 10px",
                                            }}>
                                            <option value="Pending">Draft</option>
                                            <option value="Accepted">Accepted</option>
                                            <option value="Rejected">Rejected</option>
                                        </select>
                                    </div>
                                ) : (
                                    // show if assignment exists
                                    <div className="fw-bold">
                                        <p>
                                            {" "}
                                            <FactCheckOutlinedIcon sx={{ color: "#111111B2", marginRight: 1 }} /> Status
                                        </p>
                                        <p className="ps-2">{job.assignment?.status || "N/A"}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <JobDetails
                            jobItems={job.job_items || []}
                            jobNotes={job.grouped_notes || []}
                            jobId={job.job_id}
                            workLaborItems={job.work_labor_items || []}
                        />
                    </div>
                </div>
            </div>
        );
    },
);

JobDetailViewSidePanel.displayName = "JobDetailViewSidePanel";
