import { has } from "lodash";

export function isOfType<T>(objectToCheck: unknown, uniqueKey: keyof T): objectToCheck is T {
    if (objectToCheck === undefined || objectToCheck === null) {
        return false;
    }
    if (has(objectToCheck as T, uniqueKey)) {
        return true;
    }
    return false;
}

export const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};
