import React, { useEffect } from "react";
import "./JobDetails.css"; // Optional: For additional styling
import { useState } from "react";
import axiosInstance from "../../../utils/api";

// Import the Document and Page components
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Box, Container } from "@mui/material";
import Alert from "@mui/material/Alert";
import { JobNotesSections } from "./JobNotesSection";

// Import MU Material icons
import ListIcon from "@mui/icons-material/List";
import AttachFileIcon from "@mui/icons-material/AttachFile";

// Individual components
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

// Set the worker source for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function JobDetails({ jobItems, workLaborItems, jobNotes, jobId }) {
    const userRole = localStorage.getItem("userRole");
    const [activeTab, setActiveTab] = useState("itemized");
    const [pdfLoadStatus, setPdfLoadStatus] = useState(null);

    const [pdfBlob, setPdfBlob] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [containerWidth, setContainerWidth] = useState(null);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await axiosInstance.get(`/api/job-attachments?job_id=${jobId}`, {
                    responseType: "blob",
                });
                setPdfBlob(response.data);
            } catch (error) {
                // Handle different types of errors
                if (error.response && error.response.status === 404) {
                    setPdfLoadStatus({
                        status: "info",
                        message: "A PDF is not available for this job.",
                    });
                } else {
                    setPdfLoadStatus({
                        status: "error",
                        message: "Failed to fetch the PDF. Please try again later.",
                    });
                }
            }
        };

        fetchPdf();
    }, [jobId]);

    useEffect(() => {
        // Update container width dynamically
        const updateWidth = () => {
            const container = document.querySelector(".floorplan-section");
            if (container) {
                setContainerWidth(container.clientWidth - 32);
            }
        };

        updateWidth(); // Set initial width
        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    return (
        <Container fixed sx={{ backgroundColor: "#F2F2F7", paddingBottom: 5, mt: 2 }}>
            {/* Tabs */}
            <div className="job-tabs">
                <button
                    className={`tab-item ${activeTab === "itemized" ? "active" : ""}`}
                    onClick={() => setActiveTab("itemized")}>
                    <ListIcon /> Itemized
                </button>

                <button
                    className={`tab-item ${activeTab === "floorplan" ? "active" : ""}`}
                    onClick={() => setActiveTab("floorplan")}>
                    <AttachFileIcon /> Floorplan
                </button>
            </div>

            {activeTab === "itemized" && (
                <div>
                    <div className="bg-white" style={{ padding: "10px" }}>
                        <Typography variant="body" sx={{ mt: 2, fontWeight: "bold" }}>
                            Materials Required
                        </Typography>

                        {/* If no items are available, show message below */}
                        {jobItems?.length === 0 && (
                            <div className="no-items-message my-2">No material items available</div>
                        )}

                        {/* Job Items */}
                        <Box
                            className="job-items"
                            sx={{
                                overflowX: "auto",
                                display: "flex",
                                gap: 2,
                                scrollbarWidth: "2px",
                            }}>
                            {jobItems?.map((item, index) => (
                                <Box
                                    key={index}
                                    className="job-item job-item-row"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "max-content",
                                    }}>
                                    <TextField
                                        id="outlined-helperText"
                                        label="Type"
                                        value={item.item_type}
                                        disabled
                                        sx={{ maxWidth: 130 }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Quantity"
                                        value={item.quantity}
                                        disabled
                                        sx={{ maxWidth: 90, backgroundColor: "#EAEAEB" }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Unit"
                                        value={item.unit}
                                        disabled
                                        sx={{ maxWidth: 90 }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Product Description"
                                        value={item.description}
                                        sx={{
                                            width: 500,
                                        }}
                                        disabled
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Room"
                                        value={item.room}
                                        disabled
                                        sx={{ maxWidth: 120 }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Color/Pattern"
                                        value={item.pattern}
                                        disabled
                                        sx={{ maxWidth: 220 }}
                                    />
                                </Box>
                            ))}

                            <JobNotesSections
                                notes={jobNotes?.["Material"]}
                                sectionTitle="General Notes"
                                sectionWidth="1200px"
                            />
                        </Box>

                        {/* Work Labor Items */}
                        <Typography variant="body" sx={{ mt: 2, fontWeight: "bold" }}>
                            Work Required
                        </Typography>

                        {/* If no items are available, show message below */}
                        {workLaborItems?.length === 0 && (
                            <div className="no-items-message my-2">No work labor items available</div>
                        )}

                        <Box
                            className="job-items"
                            sx={{
                                overflowX: "auto",
                                display: "flex",
                                gap: 2,
                                scrollbarWidth: "2px",
                            }}>
                            {workLaborItems?.map((item, index) => (
                                <Box
                                    key={index}
                                    className="job-item job-item-row"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "max-content",
                                    }}>
                                    <TextField
                                        id="outlined-helperText"
                                        label="Type"
                                        value="Installation"
                                        disabled
                                        sx={{ maxWidth: 126 }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Quantity"
                                        value={item.quantity}
                                        disabled
                                        sx={{ maxWidth: 90, backgroundColor: "#EAEAEB" }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Unit"
                                        className="fake-data"
                                        value={item.unit}
                                        disabled
                                        sx={{ maxWidth: 90 }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Unit Cost"
                                        className="fake-data"
                                        value={item.cost_per_unit}
                                        sx={{ maxWidth: 150, backgroundColor: "#EAEAEB" }}
                                        disabled
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Labor Description"
                                        className="fake-data"
                                        value={item.description}
                                        disabled
                                        sx={{
                                            width: 500,
                                        }}
                                    />

                                    <TextField
                                        id="outlined-helperText"
                                        label="Information"
                                        className="fake-data"
                                        value={item.information}
                                        disabled
                                        sx={{ maxWidth: 200 }}
                                    />

                                    {userRole === "admin" && (
                                        <TextField
                                            id="outlined-helperText"
                                            label="Cost"
                                            value={Number(
                                                Number(item.quantity || 0) * Number(item.cost_per_unit || 0),
                                            ).toLocaleString("en-US", {
                                                maximumFractionDigits: 2,
                                            })}
                                            disabled
                                            sx={{ maxWidth: 150, backgroundColor: "#EAEAEB" }}
                                        />
                                    )}
                                </Box>
                            ))}

                            {/* Notes for Worker */}
                            <JobNotesSections
                                notes={jobNotes?.["Labor"]}
                                sectionTitle="Notes for Worker"
                                sectionWidth="1360px"
                            />

                            {/* Total Cost Full Job */}
                            {workLaborItems.length > 0 && (
                                <Box
                                    className="job-item job-item-row"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "max-content",
                                    }}>
                                    {userRole === "admin" && (
                                        <TextField
                                            id="outlined-helperText"
                                            label="Total Cost for Job"
                                            // The true culprit is bad data import. So the api is always returning 0 or null for total cost
                                            value={workLaborItems.reduce(
                                                (acc, item) => acc + item.quantity * item.cost_per_unit,
                                                0,
                                            )}
                                            disabled
                                            sx={{ backgroundColor: "#EAEAEB", width: 1360 }}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                    </div>
                </div>
            )}

            {activeTab === "floorplan" && (
                <div className="floorplan-section px-4" style={{ overflow: "hidden", position: "relative" }}>
                    {/* Alert for errors or warnings */}
                    {pdfLoadStatus && (
                        <Alert severity={pdfLoadStatus.status} sx={{ my: 2 }}>
                            {pdfLoadStatus.message}
                        </Alert>
                    )}

                    {pdfBlob ? (
                        <div>
                            {/* Button to Open PDF */}
                            <Button
                                onClick={() => window.open(URL.createObjectURL(pdfBlob), "_blank")}
                                sx={{
                                    backgroundColor: "#2222226E",
                                    color: "white",
                                    ml: 2,
                                    textTransform: "none",
                                    padding: "10px",
                                    borderRadius: "9px",
                                    position: "relative",
                                    top: "60px",
                                    zIndex: "1000",
                                    fontSize: "16px",
                                }}>
                                Open in new Tab
                            </Button>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "20px",
                                }}>
                                <Document file={pdfBlob} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                                    {Array.from({ length: numPages }, (_, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            width={containerWidth || 830}
                                        />
                                    ))}
                                </Document>
                            </div>
                        </div>
                    ) : (
                        !pdfLoadStatus && (
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Loading PDF...
                            </Typography>
                        )
                    )}
                </div>
            )}
        </Container>
    );
}

export default JobDetails;
