import React from "react";
import "./Watermark.css"; // Make sure to import the CSS file

const Watermark = () => {
    return (
        <div className="watermark" data-testid="watermark">
            {(process.env.REACT_APP_JOB_SOURCE ?? "test").toUpperCase()}
        </div>
    );
};

export default Watermark;
