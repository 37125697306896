import React, { useState, useCallback, useMemo } from "react";
import { Container, Stack } from "@mui/material";
import { DateTime } from "luxon";

// TODO: Type definitions for props
/*
interface WorkersFilterSelectorsProps {
    onFilterChange: (city: string) => void;
}
*/

/**
 * WorkersFilterSelectors component
 * @param {WorkersFilterSelectorsProps} props
 * @param {string} props.city - city name
 * @returns {JSX.Element}
 */

export const WorkersFilterSelectors = React.memo(({ onFilterChange, lastUpdated }) => {
    const cities = ["Denver", "Las Vegas", "Phoenix"];
    const [activeCity, setActiveCity] = useState(cities[0]);

    const setCity = useCallback(
        (city) => {
            setActiveCity(city);
            onFilterChange(city);
        },
        [onFilterChange],
    );

    const timestamp = useMemo(() => {
        if (!lastUpdated) {
            return null;
        }
        const date = DateTime.fromISO(lastUpdated, {
            zone: "UTC",
        }).setZone("local");
        return `Last update at ${date.toLocaleString(DateTime.DATETIME_FULL)}`;
    }, [lastUpdated]);

    return (
        <Container maxWidth={false} disableGutters className="cities-tabs-nav">
            <Stack
                direction="row"
                sx={{ py: 2, pl: 0, ml: "100px", background: "#F2F2F7" }}
                justifyContent={"space-between"}>
                <div>
                    {cities.map((city, index) => (
                        <button
                            key={index}
                            className={`btn ${activeCity === city ? "active-city" : ""} ms-5`}
                            onClick={() => setCity(city)}>
                            {city}
                        </button>
                    ))}
                </div>
                <div style={{ marginRight: "50px", padding: "10px" }}>{timestamp}</div>
            </Stack>
        </Container>
    );
});

WorkersFilterSelectors.displayName = "WorkersFilterSelectors";
