import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Paper, Link, CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { isEqual } from "lodash";

const LoginPage = React.memo(() => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginStatus, setLoginStatus] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const authToken = localStorage.getItem("accessToken"); // Check if user is authenticated
        if (authToken) {
            navigate("/"); // Redirect to main page
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = createTheme({
        palette: {
            customGray: {
                main: "#49454F",
            },
        },
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoginStatus(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/token`,
                new URLSearchParams({
                    grant_type: "password",
                    username,
                    password,
                    scope: "",
                    client_id: "",
                    client_secret: "",
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Accept: "application/json",
                    },
                },
            );
            const accessToken = response.data.access_token;
            const userRole = response.data.role;

            // Store token in localStorage or sessionStorage
            localStorage.setItem("accessToken", accessToken);
            // Store user role
            localStorage.setItem("userRole", userRole);

            // Redirect to the home page
            window.location.href = "/";
        } catch (err) {
            console.error("Login failed:", err);
            setLoginStatus({
                status: "error",
                message: "Invalid username or password.",
            });
        } finally {
            setLoading(false);
        }
    };

    const possibleEnvironmentUserWantsToVisit = useMemo(() => {
        const currentSource = process.env.REACT_APP_JOB_SOURCE.toLowerCase();
        const mappings = {
            nancy: "Job Runner",
            dev: "Job Runner",
            development: "Job Runner",
            prod: "Nancy",
            production: "Nancy",
            jobrunner: "Nancy",
            "job-runner": "Nancy",
            job_runner: "Nancy",
            "job runner": "Nancy",
        };
        return mappings[currentSource] ?? "Nancy";
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f5f5f5",
            }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: "#e9e9ee",
                    maxWidth: 700,
                    width: "100%",
                }}>
                <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
                    Log in
                </Typography>
                <form onSubmit={handleLogin} style={{ padding: "0 50px" }}>
                    <ThemeProvider theme={theme}>
                        {/* Display Status Message */}
                        {loginStatus && (
                            <Alert severity={loginStatus.status} sx={{ my: 2 }}>
                                {loginStatus.message}
                            </Alert>
                        )}

                        <TextField
                            label="Username"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="username"
                            placeholder="Type here"
                            variant="filled"
                            color="customGray"
                            sx={{
                                "& .MuiFilledInput-root": {
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                    "&.Mui-focused": { backgroundColor: "white" },
                                },
                                mt: 5,
                            }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                            placeholder="Type here"
                            variant="filled"
                            color="customGray"
                            sx={{
                                "& .MuiFilledInput-root": {
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                    "&.Mui-focused": { backgroundColor: "white" },
                                },
                                mt: 5,
                            }}
                        />

                        <Box
                            sx={{
                                textAlign: "right",
                            }}>
                            <Link href="#" underline="hover" sx={{ fontSize: 14, color: "#49454F" }}>
                                Forgot Password?
                            </Link>
                            <Typography sx={{ textAlign: "left" }}>
                                Are you looking for the production version&nbsp;with&nbsp;
                                {possibleEnvironmentUserWantsToVisit}? Please visit&nbsp;
                                <Link
                                    href={process.env.REACT_APP_OTHER_ENV_URL}
                                    underline="hover"
                                    sx={{ fontSize: 14 }}>
                                    {possibleEnvironmentUserWantsToVisit}
                                </Link>
                            </Typography>
                        </Box>

                        <Box sx={{ textAlign: "center" }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    borderRadius: 50,
                                    minWidth: 80,
                                    height: 40,
                                    px: 4,
                                    mt: 3,
                                    background: "#fff",
                                    color: "#000",
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                }}
                                disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : "Enter"}
                            </Button>
                        </Box>
                    </ThemeProvider>
                </form>
            </Paper>
        </Box>
    );
}, isEqual);

export default LoginPage;
