import React from "react";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
const markerShadowUrl = "/images/leaflet/marker-shadow.png";

const roomIconHtml = ReactDOMServer.renderToString(<WarehouseIcon style={{ color: "#111111B2", fontSize: "24px" }} />);

const baseWhiteMarker = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="40px"
        height="55px"
        viewBox="-1 -1 42 57"
        style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            imageRendering: "optimizeQuality" as any,
            fillRule: "evenodd",
            clipRule: "evenodd",
        }}>
        <g>
            <path
                style={{ opacity: 0.951 }}
                fill="#fefffe"
                d="M 13.5,-0.5 C 17.5,-0.5 21.5,-0.5 25.5,-0.5C 32.2541,2.58628 36.9208,7.58628 39.5,14.5C 39.5,17.1667 39.5,19.8333 39.5,22.5C 34.2157,33.7266 27.8824,44.3932 20.5,54.5C 19.8333,54.5 19.1667,54.5 18.5,54.5C 10.9103,44.916 4.57692,34.5827 -0.5,23.5C -0.5,20.5 -0.5,17.5 -0.5,14.5C 2.1057,7.56146 6.77236,2.56146 13.5,-0.5 Z"
            />
        </g>
    </svg>
);
const baseWhiteMarkerStr = ReactDOMServer.renderToString(baseWhiteMarker);

const baseGrayMarker = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="40px"
        height="56px"
        viewBox="-1 -1 42 58"
        style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            imageRendering: "optimizeQuality" as any,
            fillRule: "evenodd",
            clipRule: "evenodd",
        }}>
        <g>
            <path
                style={{ opacity: 0.959 }}
                fill="#8c8c96"
                d="M 14.5,-0.5 C 17.8333,-0.5 21.1667,-0.5 24.5,-0.5C 31.4137,2.07924 36.4137,6.74591 39.5,13.5C 39.5,16.8333 39.5,20.1667 39.5,23.5C 34.1908,34.7867 27.8575,45.4534 20.5,55.5C 20.1667,55.5 19.8333,55.5 19.5,55.5C 11.1459,45.4889 4.47928,34.4889 -0.5,22.5C -0.5,20.1667 -0.5,17.8333 -0.5,15.5C 1.73787,7.59494 6.73787,2.26161 14.5,-0.5 Z"
            />
        </g>
    </svg>
);
const baseGrayMarkerStr = ReactDOMServer.renderToString(baseGrayMarker);

export const WarehouseMakerIcon = L.divIcon({
    html: `
    <div style="position: relative; display: inline-block;">
      ${baseWhiteMarkerStr}
      <div style="
        position: absolute;
        top: 2px;
        left: 6px;
        border-radius: 50%;
        padding: 2px;
      ">
        ${roomIconHtml}
      </div>
    </div>
  `,
    className: "",
    iconSize: [25, 41], // same as default
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    shadowUrl: markerShadowUrl,
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
});

export const GrayMakerIcon = L.divIcon({
    html: `
  <div style="position: relative; display: inline-block;">
    ${baseGrayMarkerStr}
    <div style="
      position: absolute;
      top: 2px;
      left: 6px;
      border-radius: 50%;
      padding: 2px;
    ">
      ${roomIconHtml}
    </div>
  </div>
`,
    className: "",
    iconSize: [25, 41], // same as default
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    shadowUrl: markerShadowUrl,
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
});

export const createClusterCustomIcon = (cluster: { getChildCount: () => number }) => {
    return L.divIcon({
        html: `
        <div style="position: relative; display: inline-block;">
          ${baseGrayMarkerStr}
          <div style="
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 700;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: white;
            text-align: center;
          ">
            <span>${cluster.getChildCount()}</span>
          </div>
        </div>
      `,
        className: "",
        iconSize: L.point(33, 33, true),
    });
};

export const getGrayIcon = (numberOfJobs: number) =>
    L.divIcon({
        html: `
  <div style="position: relative; display: inline-block;">
    ${baseGrayMarkerStr}
    <div style="
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: white;
      text-align: center;
    ">
      <span>${numberOfJobs}</span>
    </div>
  </div>
`,
        className: "",
        iconSize: L.point(33, 33, true),
    });
