import React, { useRef, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListIcon from "@mui/icons-material/List";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Container } from "@mui/material";

// TODO: Type definitions for props
/*
interface WorkerDetailsSidePanelProps {
    worker: unknown;
    closeDetailView: () => void;
    onEditWorker: (workerId: number, isEdit: boolean) => void;
    handleDelete: (workerId: number, isArchive?: boolean) => void;
}
*/

export const WorkerDetailsSidePanel = React.memo(({ worker, closeDetailView, onEditWorker, handleDelete }) => {
    const [activeTab, setActiveTab] = useState("successful");
    const detailViewRef = useRef(null);
    const handleClickOutside = useCallback(
        (event) => {
            if (detailViewRef.current && !detailViewRef.current.contains(event.target)) {
                closeDetailView();
            }
        },
        [closeDetailView],
    );
    // useEffect to add/remove event listener
    useEffect(() => {
        if (worker) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [worker, handleClickOutside]);

    const renderJobs = useCallback((jobs) => {
        return (
            <div>
                <div>
                    {/* If no items are available, show message below */}
                    {jobs.length === 0 && <div className="no-items-message">No items available</div>}
                </div>

                {/* Successful Jobs Box */}
                {jobs.length > 0 && (
                    <Box
                        className="job-items mt-2"
                        sx={{
                            overflowX: "auto",
                            display: "flex",
                            gap: 2,
                        }}>
                        {jobs.map((job, index) => (
                            <Box
                                className="job-item job-item-row bg-white p-3"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "max-content",
                                }}
                                key={index}>
                                <TextField
                                    id="outlined-helperText"
                                    label="Job Number"
                                    value={job.job_order_number}
                                    disabled
                                    sx={{ maxWidth: 130 }}
                                />

                                <TextField
                                    id="outlined-helperText"
                                    label="Description"
                                    value={job.description}
                                    disabled
                                    sx={{ maxWidth: 150 }}
                                />

                                <TextField
                                    id="outlined-helperText"
                                    label="Place Name"
                                    value={job.place_name}
                                    disabled
                                    sx={{ maxWidth: 150 }}
                                />

                                <TextField
                                    id="outlined-helperText"
                                    label="Date Requested"
                                    value={job.date_requested}
                                    disabled
                                    sx={{ maxWidth: 130 }}
                                />
                            </Box>
                        ))}
                    </Box>
                )}
            </div>
        );
    }, []);

    return (
        <div className="detail-view" ref={detailViewRef}>
            <div className="d-flex justify-content-end mb-4">
                <button onClick={closeDetailView} className="btn btn-secondary btn-close" aria-label="Close"></button>
            </div>

            <div className="container">
                <div className="container-fluid px-5">
                    {/* First Row */}
                    <div className="row d-flex align-items-stretch">
                        <div className="col-md-6 d-flex flex-column">
                            <div className="fw-bold mb-5">
                                <p>
                                    <EngineeringIcon sx={{ color: "#111111B2" }} /> Worker Name
                                </p>
                                <p className="ps-2">{worker.name}</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex flex-column">
                            <div className="fw-bold mb-5">
                                <p>
                                    <RoomIcon sx={{ color: "#111111B2" }} /> Address
                                </p>
                                <p className="ps-2">{worker.address}</p>
                            </div>
                        </div>
                    </div>

                    {/* Second Row */}
                    <div className="row d-flex align-items-stretch">
                        <div className="col-md-6 d-flex flex-column">
                            <div className="fw-bold mb-5">
                                <p>
                                    <PaymentsIcon sx={{ color: "#111111B2" }} /> Total Cost
                                </p>
                                <p className="ps-2">{worker.total_paid}</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex flex-column">
                            <div className="fw-bold mb-5">
                                <p>
                                    <PhoneIcon sx={{ color: "#111111B2" }} /> Phone Number
                                </p>
                                <p className="ps-2">{worker.contact_info}</p>
                            </div>
                        </div>
                    </div>

                    {/* Third Row with buttons aligned right */}
                    <div className="row d-flex  align-items-stretch">
                        <div className="col-md-12 d-flex flex-column">
                            <div className="fw-bold mb-5 d-flex justify-content-end w-100 gap-3">
                                <button
                                    style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        borderRadius: "50px",
                                        padding: "10px 20px",
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                        cursor: "pointer",
                                        fontWeight: "500",
                                        marginRight: "15px",
                                    }}
                                    onClick={() => onEditWorker(worker.worker_id, true)}>
                                    Edit
                                </button>

                                <button
                                    style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        borderRadius: "50px",
                                        padding: "10px 20px",
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                        cursor: "pointer",
                                        fontWeight: "500",
                                        marginRight: "15px",
                                    }}
                                    onClick={() => handleDelete(worker, true)}>
                                    Archive
                                </button>

                                <button
                                    style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        borderRadius: "50px",
                                        padding: "10px 20px",
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                        cursor: "pointer",
                                        fontWeight: "500",
                                    }}
                                    onClick={() => handleDelete(worker)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Worker Details */}
                {/* Notes */}
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography
                            gutterBottom
                            sx={{
                                color: "text.secondary",
                                fontSize: 14,
                                fontWeight: "bold",
                            }}>
                            Notes
                        </Typography>

                        <Typography sx={{ mb: 1.5 }}>{worker.notes ? worker.notes : "No notes available"}</Typography>
                    </CardContent>
                </Card>

                {/* Jobs by Worker */}
                <Container fixed sx={{ backgroundColor: "#F2F2F7", paddingBottom: 5, mt: 4 }}>
                    {/* Tabs */}
                    <div className="job-tabs">
                        <button
                            className={`tab-item ${activeTab === "successful" ? "active" : ""}`}
                            onClick={() => setActiveTab("successful")}>
                            <ListIcon /> Successful Jobs
                        </button>

                        <button
                            className={`tab-item ${activeTab === "unsuccessful" ? "active" : ""}`}
                            onClick={() => setActiveTab("unsuccessful")}>
                            <AttachFileIcon /> Ongoing Jobs
                        </button>
                    </div>
                    {renderJobs(worker[activeTab === "successful" ? "successful_jobs" : "incomplete_jobs"] ?? [])}
                </Container>
            </div>
        </div>
    );
});

WorkerDetailsSidePanel.displayName = "WorkerDetailsSidePanel";
