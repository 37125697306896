import axios from "axios";

export abstract class CSRService {
    protected static axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });
    private static didSetupInterceptors = false;

    public constructor() {
        if (!CSRService.didSetupInterceptors) {
            this.setupInterceptors();
            CSRService.didSetupInterceptors = true;
        }
    }

    private setupInterceptors = () => {
        // This is an interceptor that will run before each request to
        //  add the access token to the Authorization header.
        CSRService.axiosInstance.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("accessToken");
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        // This is an interceptor that will run before each response to check if the
        //  response status is 401 (Unauthorized). If it is, the user will be redirected
        //  to the login page.
        CSRService.axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response && error.response.status === 401) {
                    // Optionally, refresh the token here or redirect to login
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userRole");
                    window.location.href = "/login"; // Redirect to login page
                }
                return Promise.reject(error);
            },
        );
    };
}
