import { CSRService } from "./CSRService";
import { Notification } from "../models/Notification";
import { sortBy } from "lodash";
import { DateTime } from "luxon";

export class NotificationService extends CSRService {
    public constructor() {
        super();
    }

    public fetchAllNotificationsAsync = async (): Promise<Notification[]> => {
        try {
            const response = await NotificationService.axiosInstance.get<Notification[]>("/api/notifications");
            return response.data
                ? sortBy(response.data, (notification) => DateTime.fromISO(notification.delivery_time))
                : [];
        } catch (error) {
            return [];
        }
    };

    public fetchUnreadNotificationCount = async (): Promise<number> => {
        try {
            const response = await NotificationService.axiosInstance.get<number>("/api/notifications/unread-count");
            return response.data ?? 0;
        } catch (error) {
            return 0;
        }
    };

    public markNotificationAsRead = async (notificationId: string): Promise<void> => {
        try {
            await NotificationService.axiosInstance.put<void>(
                `/api/notifications/${notificationId}/update-read-status`,
            );
        } catch (error) {
            // Handle error if needed
        }
    };

    public updateNotificationDeliveryTime = async (
        notificationId: string,
        updatedDeliveryTime: string,
    ): Promise<void> => {
        try {
            await NotificationService.axiosInstance.put<void>(
                `/api/notifications/${notificationId}/update-delivery-time`,
                undefined,
                {
                    params: {
                        delivery_time: updatedDeliveryTime,
                    },
                },
            );
        } catch (error) {
            // Handle error if needed
        }
    };

    public deleteNotification = async (notificationId: string): Promise<boolean> => {
        try {
            await NotificationService.axiosInstance.delete<void>(`/api/notifications/${notificationId}`);
            return true;
        } catch (error) {
            return false;
        }
    };
}

export const notificationService = new NotificationService();
