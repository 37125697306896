import axios from "axios";
import { Job } from "../models/Job";
import { sleep } from "../utils/Helpers";

export interface Geocode {
    place_id: number;
    licence: string;
    osm_type: string;
    osm_id: number;
    boundingbox: string[];
    lat: string;
    lon: string;
    display_name: string;
    class: string;
    type: string;
    importance: number;
}

export class GeocoderService {
    public geocodeJobAsync = async (job: Job): Promise<Job> => {
        if (job.latitude && job.longitude) {
            return job;
        }
        const address = `${job.address}, ${job.city}, ${job.state ?? ""} ${job.zip_code}`;
        try {
            await sleep(500);
            const geocodeResponse = await axios.get<Geocode[]>("https://geocode.maps.co/search", {
                params: {
                    api_key: process.env.REACT_APP_GEOCODE_API_KEY,
                    q: address,
                },
            });
            const geocode = geocodeResponse.data ? geocodeResponse.data[0] : undefined;
            if (!geocode) {
                return job;
            }
            job.latitude = parseFloat(geocode.lat);
            job.longitude = parseFloat(geocode.lon);
            return job;
        } catch (e) {
            return job;
        }
    };
}

export const geocoder = new GeocoderService();
