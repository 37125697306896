import axiosInstance from "./api";

export const fetchSystemMetadata = async () => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/system-metadata`);
        return response.data.value;
    } catch (error) {
        console.error("Error fetching system metadata:", error);
        return null;
    }
};
