import React from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

export default function CustomSnackbar({ title, message, severity, isOpen = false, onClose }) {
    const handleClose = (_, reason) => {
        if (reason === "clickaway") return;
        onClose();
    };

    return (
        <Snackbar
            open={isOpen}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
            autoHideDuration={5000}
            sx={{
                "& .MuiSnackbarContent-root": {
                    borderRadius: "12px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                },
                zIndex: 200,
            }}>
            <Alert
                onClose={handleClose}
                severity={severity || "success"}
                variant="filled"
                sx={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    minWidth: "300px",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        sx={{ backgroundColor: "#fff" }}
                        onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }>
                <div>
                    <Typography variant="h6" fontWeight={"bold"}>
                        {title}
                    </Typography>
                    <br />
                    {message}
                </div>
            </Alert>
        </Snackbar>
    );
}
