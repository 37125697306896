import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";

export interface QueryParamsProps {
    getParam: (key: string) => string | undefined;
}

export const useQueryParams = (): QueryParamsProps => {
    const location = useLocation();
    const getParam = useCallback(
        (key: string) => {
            const params = new URLSearchParams(location.search);
            return params.get(key) || undefined;
        },
        [location],
    );
    return { getParam };
};

export function withParams<T extends QueryParamsProps>(WrappedComponent: React.ComponentType<T>) {
    const ComponentWithRouteParamsProps = (props: Omit<T, keyof QueryParamsProps>) => {
        const baseProps = useQueryParams();
        return <WrappedComponent {...(props as T)} {...baseProps} />;
    };

    return ComponentWithRouteParamsProps;
}
