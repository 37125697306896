import ErrorPage from "../components/ErrorPage";
import { HomePage } from "../pages/home/HomePage";
import LoginPage from "../pages/Login";
import { WorkersPage } from "../pages/workers/WorkersPage";
import { OnSitePage } from "../pages/onsite/OnSitePage";
import { NotificationsPage } from "../pages/notifications/NotificationsPage";
import { RouteKey } from "./Routes";
import { MapPage } from "../pages/map/MapPage";

export const RouteDefinitions = {
    [RouteKey.Home]: {
        Component: HomePage,
    },
    [RouteKey.Workers]: {
        Component: WorkersPage,
    },
    [RouteKey.ErrorPage]: {
        Component: ErrorPage,
    },
    [RouteKey.Login]: {
        Component: LoginPage,
    },
    [RouteKey.Onsite]: {
        Component: OnSitePage,
    },
    [RouteKey.Notifications]: {
        Component: NotificationsPage,
    },
    [RouteKey.Map]: {
        Component: MapPage,
    },
};
