import React from "react";
import "../../styles/home.css";
import { withProtectedRoute } from "../../components/ProtectedRoute";
import CustomSnackbar from "../../components/SnackbarDialog";
import { Container, Typography } from "@mui/material";
import { JobFilterSelectors } from "./components/JobFilterSelectors";
import { JobTable } from "./components/JobTable";
import { DateTime } from "luxon";
import { JobDetailViewSidePanel } from "./components/JobDetailViewSidePanel";
import ErrorPage from "../../components/ErrorPage";
import { fetchSystemMetadata } from "../../utils/apiHelpers";
import { jobService } from "../../services/JobService";
import { workerService } from "../../services/WorkerService";
import { jobRequestAssignmentService } from "../../services/JobAssignmentRequestService";
import { Worker } from "../../models/Worker";
import { Job } from "../../models/Job";
import { QueryParamsProps, withParams } from "../../hoc/navigation/withParams";
import { JobEvent, JobListeners } from "../../utils/listeners/JobListeners";

interface IOwnState {
    showAlert: boolean;
    alertContent: {
        title: string;
        message: string;
        severity: "success" | "info" | "warning" | "error";
    };
    jobs: Job[];
    isLoadingJobs: boolean;
    selectedJob?: Job;
    defaultDate: string | undefined;
    workers: Worker[];
    fullPageError?: string;
    systemLastUpdated: string | null;
    selectedFilterDate?: string;
    selectedCity: string;
}

type Props = QueryParamsProps;

export class HomePageComponent extends React.PureComponent<Props, IOwnState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
            jobs: [],
            isLoadingJobs: false,
            selectedJob: undefined,
            defaultDate: DateTime.now().toFormat("dd MMM"),
            workers: [],
            fullPageError: undefined,
            systemLastUpdated: null,
            selectedFilterDate: DateTime.now().toFormat("yyyy-MM-dd"),
            selectedCity: "Denver",
        };
    }

    public componentDidMount() {
        JobListeners.setListener(JobEvent.UpdateDateRequested, () => {
            this.fetchJobsAndWorkers({
                city: this.state.selectedCity,
                date: this.state.selectedFilterDate,
            });
        });
        this.fetchJobsAndWorkers({
            city: "Denver",
            date: DateTime.now().toFormat("yyyy-MM-dd"),
        });
        this.fetchSystemMetadata();
        const jobId = this.props.getParam("jobId");
        if (jobId) {
            jobService.fetchJobByIdAsync(jobId).then((job) => {
                if (job) {
                    this.setState({ selectedJob: job });
                } else {
                    this.setState({
                        alertContent: {
                            title: "Error",
                            message: "Failed to fetch job details",
                            severity: "error",
                        },
                        showAlert: true,
                    });
                }
            });
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.getParam("jobId") !== prevProps.getParam("jobId")) {
            const jobId = this.props.getParam("jobId");
            if (jobId) {
                jobService.fetchJobByIdAsync(jobId).then((job) => {
                    if (job) {
                        this.setState({ selectedJob: job });
                    } else {
                        this.setState({
                            alertContent: {
                                title: "Error",
                                message: "Failed to fetch job details",
                                severity: "error",
                            },
                            showAlert: true,
                        });
                    }
                });
            }
        }
    }

    public componentWillUnmount() {
        JobListeners.removeListener(JobEvent.UpdateDateRequested);
    }

    public render() {
        return this.state.fullPageError ? (
            <ErrorPage
                mainHeading="Oops.."
                subHeading={`There was an error fetching the data: ${this.state.fullPageError}`}
            />
        ) : (
            <>
                <CustomSnackbar
                    title={this.state.alertContent.title}
                    message={this.state.alertContent.message}
                    severity={this.state.alertContent.severity}
                    isOpen={this.state.showAlert}
                    onClose={this.hideAlert}
                />
                <Container sx={{ pl: 0 }} maxWidth={false} disableGutters>
                    <JobFilterSelectors
                        onFilterChange={({ city, date }: { city: string; date: string }) => {
                            this.setState({ selectedFilterDate: date }, () => {
                                this.fetchJobsAndWorkers({
                                    city,
                                    date,
                                });
                            });
                        }}
                        defaultFormattedDate={this.state.defaultDate}
                        lastUpdated={this.state.systemLastUpdated}
                    />
                    <Container sx={{ mb: 5, background: "white", py: 4, pl: "100px" }} maxWidth={false} disableGutters>
                        <JobTable
                            onJobClick={this.setSelectedJob}
                            isLoading={this.state.isLoadingJobs}
                            jobs={this.state.jobs}
                            workers={this.state.workers}
                            onSaveWorkerData={this.saveWorkerData}
                            onJobsUpdated={(jobs: Job[]) => this.setState({ jobs })}
                            onRefreshJobs={this.refreshJobs}
                        />
                        <hr style={{ marginTop: "4rem" }} />
                        <Typography variant="subtitle1" color="#626262">
                            {this.state.jobs.length === 1
                                ? `${this.state.jobs.length} Job`
                                : `${this.state.jobs.length} Jobs`}
                            &nbsp;Displayed
                        </Typography>
                        {this.state.selectedJob ? (
                            <JobDetailViewSidePanel
                                job={this.state.selectedJob}
                                jobs={this.state.jobs}
                                workers={this.state.workers}
                                onSaveWorkerData={this.saveWorkerData}
                                onJobsUpdated={(jobs: Job[]) => this.setState({ jobs })}
                                closeDetailView={() => this.setState({ selectedJob: undefined })}
                            />
                        ) : null}
                    </Container>
                </Container>
            </>
        );
    }

    private refreshJobs = async () => {
        this.setState({ isLoadingJobs: true }, () => {
            jobService
                .importNewJobsAsync({
                    warehouse: this.state.selectedCity,
                    date: this.state.selectedFilterDate,
                })
                .then(() => {
                    this.fetchJobsAndWorkers({
                        city: this.state.selectedCity,
                        date: this.state.selectedFilterDate,
                    }).then(this.fetchSystemMetadata);
                })
                .catch(() => {
                    this.setState({
                        alertContent: {
                            title: "Failed to refresh jobs",
                            message: "We failed to refresh the jobs, please try again later",
                            severity: "error",
                        },
                        isLoadingJobs: false,
                        showAlert: true,
                    });
                });
        });
    };

    private fetchSystemMetadata = async () => {
        const systemLastUpdated = await fetchSystemMetadata();
        if (systemLastUpdated !== null) {
            this.setState({ systemLastUpdated });
        }
    };

    private fetchJobsAndWorkers = async ({ city, date }: { city: string; date?: string }) => {
        this.setState({ isLoadingJobs: true, selectedJob: undefined });

        try {
            // Fetch job data
            const response = await jobService.fetchJobsAsync(city, date);
            const jobs = response?.jobs ?? [];
            let date_requested = response?.date_requested ?? date;

            if (!date_requested) {
                date_requested = DateTime.now().toFormat("yyyy-MM-dd");
            }
            this.setState({
                defaultDate: DateTime.fromFormat(date_requested, "yyyy-MM-dd").toFormat("dd MMM"),
            });

            // Fetch workers data
            const workers = await workerService.fetchWorkersAsync(city, date);
            this.setState({ jobs, workers });
        } catch (error) {
            if (error && (error as Error).message) {
                this.setState({ fullPageError: (error as Error).message });
            }
        } finally {
            this.setState({ isLoadingJobs: false });
        }
    };

    private hideAlert = () => {
        this.setState({
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
        });
    };

    private setSelectedJob = (jobId: string) => {
        const selectedJob = this.state.jobs.find((job) => job.job_id === jobId);
        this.setState({ selectedJob });
    };

    private saveWorkerData = async (jobId: string, worker_id: string | null, worker_name: string, jobOrder: string) => {
        try {
            const action = worker_id ? "assigned" : "unassigned";
            await jobService.assignWorkerToJobAsync(jobId, worker_id);

            // Delete the Job Request Assignment object if the worker is unassigned
            if (!worker_id && this.state.jobs.find((job) => job.job_id === jobId)?.assignment) {
                const assignmentId = this.state.jobs.find((job) => job.job_id === jobId)?.assignment?.assignment_id;

                await jobRequestAssignmentService.deleteAssignmentAsync(assignmentId ?? "");

                console.log("Worker data deleted successfully");
                this.setState({
                    showAlert: true,
                    alertContent: {
                        title: "Unassigned Job",
                        message: `${worker_name} was ${action} from job order ${jobOrder}`,
                        severity: "success",
                    },
                });
                return;
            } else {
                // Create a new Job Request Assignment object
                const jobRequestAssignment = {
                    job_id: jobId,
                    rejection_reason: null,
                    status: "Pending",
                    worker_id: worker_id,
                };

                // Save the Job Request Assignment object to the database
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                await jobRequestAssignmentService.createAssignmentAsync(jobRequestAssignment as any);
            }

            this.setState({
                showAlert: true,
                alertContent: {
                    title: "Assigned Job",
                    message: `${worker_name} was ${action} to job order ${jobOrder}`,
                    severity: "success",
                },
            });
        } catch (error) {
            this.setState({
                showAlert: true,
                alertContent: {
                    title: "Error",
                    message: "Failed to save worker data",
                    severity: "error",
                },
            });
        }
    };
}

export const HomePage = withProtectedRoute(withParams(HomePageComponent));
