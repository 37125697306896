import React, { PureComponent } from "react";
import { NotificationContextProps } from "../../hoc/notifications/NotificationsContext";
import { withNotifications } from "../../hoc/notifications/withNotifications";
import { withProtectedRoute } from "../../components/ProtectedRoute";
import { Container } from "@mui/material";
import { DateTime } from "luxon";
import { NotificationsFilterSelector } from "./components/NotificationsFilterSelector";
import { NotificationCard } from "./components/NotificationCard";
import { NotificationInteractionType } from "../../models/Notification";

type Props = NotificationContextProps;

interface IOwnState {
    defaultDate: string;
}

export class NoticationsPageComponent extends PureComponent<Props, IOwnState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            defaultDate: DateTime.now().toFormat("dd MMM"),
        };
    }

    public componentDidMount() {
        this.props.fetchNotifications();
    }

    public render() {
        return (
            <Container sx={{ pl: 0 }} maxWidth={false} disableGutters>
                <NotificationsFilterSelector
                    onFilterChange={({ date }) => this.setState({ defaultDate: date })}
                    defaultFormattedDate={this.state.defaultDate}
                />
                <Container sx={{ mb: 5, background: "white", py: 4, pl: "100px" }} maxWidth={false} disableGutters>
                    <div id="notificationsContainer">
                        {this.props.notifications.map((notification) => (
                            <NotificationCard
                                key={notification.notification_id}
                                notification={notification}
                                onDismiss={this.props.dismissNotification}
                                onUserInteraction={this.onUserInteraction}
                            />
                        ))}
                    </div>
                </Container>
            </Container>
        );
    }

    private onUserInteraction = (id: string, type: NotificationInteractionType) => {
        this.props.markAsRead(id);
        // other cases are handled by the notification card
        switch (type) {
            case NotificationInteractionType.AddForTomorrow:
                this.props.updateJobDeliveryTime(id);
                break;
        }
    };
}

export const NotificationsPage = withNotifications(
    withProtectedRoute(NoticationsPageComponent, { requiresAdmin: true }),
);
