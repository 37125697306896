import React, { PureComponent } from "react";
import { withProtectedRoute } from "../../components/ProtectedRoute";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Container } from "@mui/material";
import { Job } from "../../models/Job";
import { JobFilterSelectors } from "../home/components/JobFilterSelectors";
import { fetchSystemMetadata } from "../../utils/apiHelpers";
import { jobService } from "../../services/JobService";
import { DateTime } from "luxon";
import { workerService } from "../../services/WorkerService";
import { Worker } from "../../models/Worker";
import { Warehouse } from "../../models/Warehouse";
import { warehouseService } from "../../services/WarehouseService";
import { Map as LeafletMap } from "leaflet";
import { geocoder } from "../../services/GeocoderService";
import "leaflet/dist/leaflet.css";
import { WarehouseMakerIcon } from "./components/MapIcons";
import { ClusterGroupWithTooltips } from "./components/JobClusterGroups";
import { JobDetailViewSidePanel } from "../home/components/JobDetailViewSidePanel";
import { jobRequestAssignmentService } from "../../services/JobAssignmentRequestService";
import CustomSnackbar from "../../components/SnackbarDialog";

interface IOwnState {
    showAlert: boolean;
    alertContent: {
        title: string;
        message: string;
        severity: "success" | "info" | "warning" | "error";
    };
    jobs: Job[];
    isLoadingJobs: boolean;
    selectedJob?: Job;
    defaultDate: string | undefined;
    workers: Worker[];
    fullPageError?: string;
    systemLastUpdated: string | null;
    selectedFilterDate?: string;
    selectedCity: string;
    warehouses: Warehouse[];
    center: [number, number, number?];
}

export class MapPageComponent extends PureComponent<unknown, IOwnState> {
    private map: LeafletMap | null = null;
    public constructor(props: unknown) {
        super(props);
        this.state = {
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
            jobs: [],
            isLoadingJobs: false,
            selectedJob: undefined,
            defaultDate: DateTime.now().toFormat("dd MMM"),
            workers: [],
            fullPageError: undefined,
            systemLastUpdated: null,
            selectedFilterDate: undefined,
            selectedCity: "Denver",
            warehouses: [],
            center: [40.011313, -97.300593],
        };
    }

    public componentDidMount() {
        this.fetchJobsAndWorkers({
            city: "Denver",
            date: DateTime.now().toFormat("yyyy-MM-dd"),
        });
        this.fetchSystemMetadata();
    }

    public render() {
        return (
            <Container sx={{ pl: 0 }} maxWidth={false} disableGutters>
                <CustomSnackbar
                    title={this.state.alertContent.title}
                    message={this.state.alertContent.message}
                    severity={this.state.alertContent.severity}
                    isOpen={this.state.showAlert}
                    onClose={this.hideAlert}
                />
                <JobFilterSelectors
                    onFilterChange={({ city, date }: { city: string; date: string }) => {
                        this.setState({ selectedFilterDate: date }, () => {
                            this.fetchJobsAndWorkers({
                                city,
                                date,
                            });
                        });
                    }}
                    defaultFormattedDate={this.state.defaultDate}
                    lastUpdated={this.state.systemLastUpdated}
                />
                <Container
                    sx={{ mb: 5, background: "white", height: "50rem", pl: "100px" }}
                    maxWidth={false}
                    disableGutters>
                    <MapContainer
                        style={{
                            height: "100%",
                            zIndex: 0,
                        }}
                        center={this.state.center}
                        zoom={5}
                        scrollWheelZoom={true}
                        ref={(ref) => {
                            this.map = ref;
                        }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {this.state.warehouses.map((warehouse, index) => {
                            const coordinatesString = (warehouse.coordinates ?? "40.011313,-97.300593").split(",");
                            const coordinates: [number, number, number?] = coordinatesString.map((coor) =>
                                parseFloat(coor.trim()),
                            ) as [number, number, number?];
                            return (
                                <Marker
                                    position={coordinates}
                                    key={index}
                                    icon={WarehouseMakerIcon}
                                    data-testid={warehouse.id}
                                />
                            );
                        })}
                        <ClusterGroupWithTooltips jobs={this.state.jobs} onJobClick={this.onJobMarkerClicker} />
                    </MapContainer>
                    {this.state.selectedJob ? (
                        <JobDetailViewSidePanel
                            job={this.state.selectedJob}
                            jobs={this.state.jobs}
                            workers={this.state.workers}
                            onSaveWorkerData={this.saveWorkerData}
                            onJobsUpdated={(jobs: Job[]) => this.setState({ jobs })}
                            closeDetailView={() => this.setState({ selectedJob: undefined })}
                            style={{
                                zIndex: 999,
                            }}
                        />
                    ) : null}
                </Container>
            </Container>
        );
    }

    private onJobMarkerClicker = (id: string) => {
        const job = this.state.jobs.find((j) => j.job_id === id);
        if (job) {
            this.setState({ selectedJob: job });
        }
    };

    private fetchSystemMetadata = async () => {
        const systemLastUpdated = await fetchSystemMetadata();
        if (systemLastUpdated !== null) {
            this.setState({ systemLastUpdated });
        }
    };

    private fetchJobsAndWorkers = async ({ city, date }: { city: string; date?: string }) => {
        this.setState({ isLoadingJobs: true, selectedJob: undefined });
        this.fetchWarehouses(city);

        try {
            // Fetch job data
            const response = await jobService.fetchJobsAsync(city, date);
            const jobs = response?.jobs ?? [];
            for (let i = 0; i < jobs.length; i++) {
                jobs[i] = await geocoder.geocodeJobAsync(jobs[i]);
            }
            let date_requested = response?.date_requested ?? date;

            if (!date_requested) {
                date_requested = DateTime.now().toFormat("yyyy-MM-dd");
            }
            this.setState({
                defaultDate: DateTime.fromFormat(date_requested, "yyyy-MM-dd").toFormat("dd MMM"),
            });

            // Fetch workers data
            const workers = await workerService.fetchWorkersAsync(city, date);
            this.setState({ jobs, workers });
        } catch (error) {
            if (error && (error as Error).message) {
                this.setState({ fullPageError: (error as Error).message });
            }
        } finally {
            this.setState({ isLoadingJobs: false });
        }
    };

    private fetchWarehouses = (city: string) => {
        warehouseService.fetchWarehouses(city).then((warehouses) => {
            const centerWarehouse = warehouses.find((ware) => ware.coordinates);
            if (centerWarehouse) {
                const coordinatesString = (centerWarehouse.coordinates ?? "51.505, -0.09").split(",");
                const coordinates: [number, number, number?] = coordinatesString.map((coor) =>
                    parseFloat(coor.trim()),
                ) as [number, number, number?];
                this.setState(
                    {
                        center: coordinates,
                    },
                    () => {
                        this.map?.panTo(coordinates);
                        this.map?.setZoom(10);
                    },
                );
            }
            this.setState({ warehouses: warehouses.filter((warehouse) => warehouse.coordinates) });
        });
    };

    private hideAlert = () => {
        this.setState({
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
        });
    };

    private saveWorkerData = async (jobId: string, worker_id: string | null, worker_name: string, jobOrder: string) => {
        try {
            const action = worker_id ? "assigned" : "unassigned";
            await jobService.assignWorkerToJobAsync(jobId, worker_id);

            // Delete the Job Request Assignment object if the worker is unassigned
            if (!worker_id && this.state.jobs.find((job) => job.job_id === jobId)?.assignment) {
                const assignmentId = this.state.jobs.find((job) => job.job_id === jobId)?.assignment?.assignment_id;

                await jobRequestAssignmentService.deleteAssignmentAsync(assignmentId ?? "");

                console.log("Worker data deleted successfully");
                this.setState({
                    showAlert: true,
                    alertContent: {
                        title: "Unassigned Job",
                        message: `${worker_name} was ${action} from job order ${jobOrder}`,
                        severity: "success",
                    },
                });
                return;
            } else {
                // Create a new Job Request Assignment object
                const jobRequestAssignment = {
                    job_id: jobId,
                    rejection_reason: null,
                    status: "Pending",
                    worker_id: worker_id,
                };

                // Save the Job Request Assignment object to the database
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                await jobRequestAssignmentService.createAssignmentAsync(jobRequestAssignment as any);
            }

            this.setState({
                showAlert: true,
                alertContent: {
                    title: "Assigned Job",
                    message: `${worker_name} was ${action} to job order ${jobOrder}`,
                    severity: "success",
                },
            });
        } catch (error) {
            this.setState({
                showAlert: true,
                alertContent: {
                    title: "Error",
                    message: "Failed to save worker data",
                    severity: "error",
                },
            });
        }
    };
}

export const MapPage = withProtectedRoute(MapPageComponent);
