import React, { useContext } from "react";
import { NotificationContext, NotificationContextProps } from "./NotificationsContext";

export const useNotifications = (): NotificationContextProps => {
    const context = useContext(NotificationContext);
    return context;
};

export function withNotifications<T extends NotificationContextProps = NotificationContextProps>(
    WrappedComponent: React.ComponentType<T>,
) {
    const ComponentWithNotificationsProps = (props: Omit<T, keyof NotificationContextProps>) => {
        const baseProps = useContext(NotificationContext);
        return <WrappedComponent {...(props as T)} {...baseProps} />;
    };

    return ComponentWithNotificationsProps;
}
