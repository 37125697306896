import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";

// Material UI Icons
import RoomIcon from "@mui/icons-material/Room";
import CloseIcon from "@mui/icons-material/Close";
import EngineeringIcon from "@mui/icons-material/Engineering";
import WorkIcon from "@mui/icons-material/Work";
import NumbersIcon from "@mui/icons-material/Numbers";
import { JobNotesSections } from "../../home/components/JobNotesSection";

// TODO: Type definitions

export const RejectionAssignmentModal = React.memo(({ job, onClose, onSubmit }) => {
    const reasons = ["Sick", "Low pay", "Too far", "No time", "Dislike task", "Vehicle problems"];
    const [formRejectionReason, setRejectionReason] = useState("");
    const [formNotes, setNotes] = useState("");
    /**
     * Modal status object
     * @typedef {Object} ModalStatus
     * @property {string} status - The status of the modal ("success" | "info" | "warning" | "error")
     * @property {string} message - The message to display in the modal
     * @property {object} errorControls - The error controls to display in the modal
     */
    const [modalStatus, setModalStatus] = useState(null);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            // Clear previous error messages
            setModalStatus(null);

            // Create the data object
            const formData = {
                status: "Rejected",
                worker_id: job.worker_id,
                rejection_reason: formRejectionReason,
                job_id: job.job_id,
                jobOrder: job.job_order_number,
                jobRequestAssignmentID: job.assignment?.assignment_id,
                notes: formNotes,
            };

            await onSubmit(formData).catch((error) => {
                console.error(`Failed to reject job:`, error);
                setModalStatus({
                    status: "error",
                    message: `Failed to reject the job. Please try again.`,
                });
            });

            onClose();

            // TODO 2: Save notes to the database
        },
        [formRejectionReason, formNotes, onSubmit],
    );

    return (
        <Modal open onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "75%",
                    bgcolor: "background.paper",
                    background: "#E5E5EA",
                    borderRadius: "20px",
                    boxShadow: 24,
                    padding: "30px 50px",
                }}>
                {/* Header Area */}
                <Grid container justifyContent="space-between" alignItems="flex-start" sx={{ mt: 2 }}>
                    <Typography id="modal-modal-title" sx={{ fontWeight: "bold" }} variant="h4" component="h2" mb={5}>
                        Job {job.job_order_number} Rejected
                    </Typography>
                    <IconButton onClick={onClose} sx={{ fontSize: "0.5rem", backgroundColor: "white" }}>
                        <CloseIcon sx={{ fontSize: "1.5rem" }} />
                    </IconButton>
                </Grid>

                {/* Form Area */}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} className="px-md-5">
                        {/* Display Error Message */}
                        {modalStatus && (
                            <Alert severity={modalStatus.status} sx={{ my: 2 }}>
                                {modalStatus.message}
                            </Alert>
                        )}

                        {/* Left Section: Job Details */}
                        <Grid size={5}>
                            <div className="row d-flex align-items-stretch">
                                <div>
                                    <div className="fw-bold mb-4">
                                        <p>
                                            <EngineeringIcon sx={{ color: "#111111B2" }} /> {job.worker_name}
                                        </p>
                                    </div>

                                    <div className="fw-bold mb-4">
                                        <p>
                                            <WorkIcon sx={{ color: "#111111B2" }} /> Job Description
                                        </p>
                                        <p className="ps-2">{job.description}</p>
                                    </div>

                                    <div className="fw-bold mb-4">
                                        <p>
                                            <RoomIcon sx={{ color: "#111111B2" }} /> Address
                                        </p>
                                        <p className="ps-2">{job.address}</p>
                                    </div>

                                    <div className="fw-bold mb-4">
                                        <p>
                                            <NumbersIcon sx={{ color: "#111111B2" }} /> Job Order
                                        </p>
                                        <p className="ps-2">{job.job_order_number}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        {/* Right Section: Notes */}
                        <Grid size={7}>
                            <FormControl
                                fullWidth
                                required
                                error={modalStatus?.errorControls?.formRejectionReason}
                                sx={{ mb: 2 }}>
                                <InputLabel
                                    id="reason-label"
                                    color="primary"
                                    sx={{
                                        transform: formRejectionReason
                                            ? "translate(0, -20px) scale(0.75)"
                                            : "translate(10px, 15px) scale(1)",
                                        transition: "transform 0.2s ease-in-out",
                                    }}>
                                    <RoomIcon /> Select Reason
                                </InputLabel>
                                <Select
                                    labelId="reason-label"
                                    id="reason"
                                    value={formRejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    sx={{ backgroundColor: "#1D1B2014" }}>
                                    {reasons.map((reason, index) => (
                                        <MenuItem key={index} value={reason}>
                                            {reason}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                label="Notes"
                                multiline
                                rows={13}
                                variant="filled"
                                value={formNotes}
                                onChange={(e) => setNotes(e.target.value)}
                                placeholder="Type Here"
                                helperText="Max 2000 characters"
                                slotProps={{ htmlInput: { maxLength: 2000 } }}
                                sx={{
                                    "& .MuiFilledInput-root": {
                                        backgroundColor: "white",
                                        "&:hover": { backgroundColor: "white" },
                                        "&.Mui-focused": { backgroundColor: "white" },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Action Buttons */}
                    <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
                        <button
                            style={{
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "50px",
                                padding: "10px 20px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                cursor: "pointer",
                                fontWeight: "500",
                            }}>
                            Done
                        </button>

                        <button
                            style={{
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "50px",
                                padding: "10px 20px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                                cursor: "pointer",
                                fontWeight: "500",
                            }}
                            onClick={onClose}>
                            Cancel
                        </button>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
});

RejectionAssignmentModal.displayName = "RejectionAssignmentModal";
