export enum NotificationReadStatus {
    UNREAD = "Unread",
    READ = "Read",
}

export enum NotificationTitle {
    RejectedJob = "Rejected Job",
    AcceptedJob = "Accepted Job",
}

export interface Notification {
    message: string;
    title: NotificationTitle;
    read_status: NotificationReadStatus;
    user_id: string;
    type: string;
    job_id: string | null;
    worker_id: string | null;
    notification_id: string;
    delivery_time: string;
    worker_name?: string;
    job_order_number?: string;
}

export enum NotificationInteractionType {
    Reassign,
    AddForTomorrow,
    Read,
    Dismiss,
}
