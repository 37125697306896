import { CSRService } from "./CSRService";
import { Warehouse } from "../models/Warehouse";

export class WarehouseService extends CSRService {
    public constructor() {
        super();
    }

    public fetchWarehouses = async (name: string): Promise<Warehouse[]> => {
        try {
            const response = await WarehouseService.axiosInstance.get<Warehouse[]>("/api/warehouse", {
                params: {
                    name,
                },
            });
            return response.data ?? [];
        } catch (error) {
            return [];
        }
    };
}

export const warehouseService = new WarehouseService();
