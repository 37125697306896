import { CSRService } from "./CSRService";
import { JobFetchResponse } from "../models/Job";

interface AssignmentData {
    job_id: string;
    worker_id: string;
    status?: "Pending" | "Accepted" | "Rejected";
    rejection_reason?: string | null;
}
/**
 * Service for manipulating job assignment requests for workers and jobs from the API
 */
export class JobRequestAssignmentService extends CSRService {
    public constructor() {
        super();
    }

    /**
     * Creates a new job request assignment
     * @param assignmentData - The data for the assignment to create
     * @returns The created assignment object
     */
    public createAssignmentAsync = async (assignmentData: AssignmentData): Promise<JobFetchResponse> => {
        return JobRequestAssignmentService.axiosInstance.post(`/api/job-request-assignment`, assignmentData);
    };

    /**
     * Deletes a job request assignment
     * @param assignmentId - The ID of the assignment to delete
     */
    public deleteAssignmentAsync = async (assignmentId: string): Promise<void> => {
        await JobRequestAssignmentService.axiosInstance.delete(`/api/job-request-assignment/${assignmentId}`);
    };

    /**
     * Updates an assignment status and rejection reason
     * @param assignmentId - ID of the assignment to update
     * @param status - New status (Accepted/Rejected)
     * @param rejectionReason - Optional rejection reason
     */
    public updateAssignmentStatusAsync = async (
        assignmentId: string,
        status: "Accepted" | "Rejected",
        rejectionReason?: string,
    ): Promise<void> => {
        await JobRequestAssignmentService.axiosInstance.put(`/api/job-request-assignment/${assignmentId}`, null, {
            params: {
                status,
                ...(rejectionReason && { rejection_reason: rejectionReason }),
            },
        });
    };
}

export const jobRequestAssignmentService = new JobRequestAssignmentService();
