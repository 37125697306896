import React from "react";
import { useLocation, Link } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PlaceIcon from "@mui/icons-material/Place";

import { Box, IconButton, Badge } from "@mui/material";
import { handleLogout } from "../utils";
import { RouteKey } from "../routes/Routes";
import { useNotifications } from "../hoc/notifications/withNotifications";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const Sidebar = React.memo(() => {
    const location = useLocation();
    const isAdmin = localStorage.getItem("userRole") === "admin";
    const notifications = useNotifications();

    if (location.pathname === "/login") return null; // Don't render on login page

    return (
        <Box component="nav" className="sidebar">
            <ul className="sidebar-list">
                <li className="sidebar-item my-4 mb-5">
                    <IconButton color="inherit">
                        <MenuIcon />
                    </IconButton>
                </li>
                <li className="sidebar-item">
                    <Link to={RouteKey.Home} className="sidebar-link">
                        <div className="sidebar-icon mb-1">
                            <ListIcon />
                        </div>
                        Full List
                    </Link>
                </li>

                <li className="sidebar-item">
                    <Link to={RouteKey.Onsite} className="sidebar-link">
                        <div className="sidebar-icon mb-1">
                            <PendingActionsIcon />
                        </div>
                        On Site
                    </Link>
                </li>

                <li className="sidebar-item">
                    <Link to={RouteKey.Workers} className="sidebar-link">
                        <div className="sidebar-icon mb-1">
                            <GroupsOutlinedIcon />
                        </div>
                        Workers
                    </Link>
                </li>
                {isAdmin ? (
                    <>
                        <li className="sidebar-item">
                            <Link to={RouteKey.Notifications} className="sidebar-link">
                                <div className="sidebar-icon mb-1">
                                    <Badge
                                        badgeContent={notifications.unreadNotificationsCount}
                                        className="notification-badge">
                                        <NotificationsIcon />
                                    </Badge>
                                </div>
                                Notifications
                            </Link>
                        </li>
                        <li className="sidebar-item">
                            <Link to={RouteKey.Map} className="sidebar-link">
                                <div className="sidebar-icon mb-1">
                                    <PlaceIcon />
                                </div>
                                Map
                            </Link>
                        </li>
                    </>
                ) : null}

                <hr className="sidebar-divider" />
                <li className="sidebar-item">
                    <Link to={RouteKey.Login} className="sidebar-link" onClick={handleLogout}>
                        <div className="sidebar-icon mb-1">
                            <LogoutIcon />
                        </div>
                        Logout
                    </Link>
                </li>
            </ul>
        </Box>
    );
});

Sidebar.displayName = "Sidebar";
