import React from "react";
import "../../styles/home.css";
import { withProtectedRoute } from "../../components/ProtectedRoute";
import CustomSnackbar from "../../components/SnackbarDialog";
import { Container } from "@mui/material";
import { JobFilterSelectors } from "../home/components/JobFilterSelectors";
import { OnSiteTable } from "./components/OnSiteTable";
import { DateTime } from "luxon";
import axiosInstance from "../../utils/api";
import ErrorPage from "../../components/ErrorPage";
import { fetchSystemMetadata } from "../../utils/apiHelpers";
import { JobDetailViewSidePanel } from "../home/components/JobDetailViewSidePanel";
import { RejectionAssignmentModal } from "./components/RejectionAssignmentModal";
import { jobRequestAssignmentService } from "../../services/JobAssignmentRequestService";
import { jobService } from "../../services/JobService";
import { JobEvent, JobListeners } from "../../utils/listeners/JobListeners";

// TODO: Type definitions for local component state
/*
interface IOwnState {
    showAlert: boolean;
    alertContent: {
        title: string;
        message: string;
        severity: "success" | "info" | "warning" | "error";
    };
    jobs: unknown[]
    isLoadingJobs: boolean;
    selectedJob?: unknown;
    defaultDate: string | undefined;
    workers: unknown[];
    fullPageError?: unknown;
}
    */

export class OnSitePageComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
            jobs: [],
            isLoadingJobs: false,
            selectedJob: undefined,
            defaultDate: DateTime.now().toFormat("dd MMM"),
            workers: [],
            fullPageError: undefined,
            systemLastUpdated: null,
            rejectionAssignmentModal: null,
            selectedCity: "Denver",
        };
    }

    componentDidMount() {
        JobListeners.setListener(JobEvent.UpdateDateRequested, () => {
            this.fetchJobsAndWorkers({
                city: this.state.selectedCity,
                date: this.state.defaultDate,
            });
        });
        this._fetchJobs({
            city: "Denver",
            date: DateTime.now().toFormat("yyyy-MM-dd"),
        });

        this._fetchSystemMetadata();
    }

    componentWillUnmount() {
        JobListeners.removeListener(JobEvent.UpdateDateRequested);
    }

    render() {
        return this.state.fullPageError ? (
            <ErrorPage
                mainHeading="Oops.."
                subHeading={`There was an error fetching the data: ${this.state.fullPageError}`}
            />
        ) : (
            <>
                <CustomSnackbar
                    title={this.state.alertContent.title}
                    message={this.state.alertContent.message}
                    severity={this.state.alertContent.severity}
                    isOpen={this.state.showAlert}
                    onClose={this._hideAlert}
                />
                <Container sx={{ pl: 0 }} maxWidth={false} disableGutters>
                    <JobFilterSelectors
                        onFilterChange={this._fetchJobs}
                        defaultFormattedDate={this.state.defaultDate}
                        lastUpdated={this.state.systemLastUpdated}
                    />
                    <Container sx={{ mb: 5, background: "white", py: 4, pl: "100px" }} maxWidth={false} disableGutters>
                        <OnSiteTable
                            onJobClick={this._setSelectedJob}
                            key={this.state.jobs.length}
                            isLoading={this.state.isLoadingJobs}
                            jobs={this.state.jobs}
                            onSaveAssigmentStatus={this._saveAssigmentStatus}
                            onJobsUpdated={(jobs) => this.setState({ jobs })}
                            onRejectionAssignmentRequest={(job) => this.setState({ rejectionAssignmentModal: { job } })}
                        />

                        {/* Job Detail View Side Panel */}
                        {this.state.selectedJob ? (
                            <JobDetailViewSidePanel
                                job={this.state.selectedJob}
                                jobs={this.state.jobs}
                                workers={[]}
                                onJobsUpdated={(jobs) => this.setState({ jobs })}
                                closeDetailView={() => this.setState({ selectedJob: undefined })}
                                onSaveAssigmentStatus={this._saveAssigmentStatus}
                                onRejectionAssignmentRequest={(job) =>
                                    this.setState({ rejectionAssignmentModal: { job } })
                                }
                                isAssignmentPage={true}
                            />
                        ) : null}

                        {/* Rejection Assignment Modal */}
                        {this.state.rejectionAssignmentModal ? (
                            <RejectionAssignmentModal
                                job={this.state.rejectionAssignmentModal.job}
                                onClose={() => this.setState({ rejectionAssignmentModal: null })}
                                onSubmit={this._saveAssigmentStatus}
                            />
                        ) : null}
                    </Container>
                </Container>
            </>
        );
    }

    _fetchSystemMetadata = async () => {
        const systemLastUpdated = await fetchSystemMetadata();
        if (systemLastUpdated !== null) {
            this.setState({ systemLastUpdated });
        }
    };

    _fetchJobs = async ({ city, date }) => {
        this.setState({ isLoadingJobs: true, selectedJob: undefined });

        try {
            // Build query parameters dynamically
            const params = new URLSearchParams();
            if (city) params.append("warehouse", city);
            if (date) params.append("date_requested", date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd") : null);
            params.append("is_assignment_page", "true");

            // Fetch job data
            const jobsResponse = await axiosInstance.get(`/api/jobs?${params.toString()}`);
            const jobs = jobsResponse.data?.jobs;
            let date_requested = jobsResponse.data?.date_requested ?? date;

            if (!date_requested) {
                date_requested = DateTime.now().toFormat("yyyy-MM-dd");
            }
            this.setState({
                defaultDate: DateTime.fromFormat(date_requested, "yyyy-MM-dd").toFormat("dd MMM"),
                selectedCity: city,
            });

            this.setState({ jobs });
        } catch (error) {
            console.error("Error fetching data:", error);
            if (error && error.message) {
                this.setState({ fullPageError: error.message });
            }
        } finally {
            this.setState({ isLoadingJobs: false });
        }
    };

    _hideAlert = () => {
        this.setState({
            showAlert: false,
            alertContent: {
                title: "",
                message: "",
                severity: "success",
            },
        });
    };

    _setSelectedJob = (jobId) => {
        const selectedJob = this.state.jobs.find((job) => job.job_id === jobId);
        this.setState({ selectedJob });
    };

    _saveAssigmentStatus = async (data) => {
        try {
            let { jobOrder, status, rejection_reason, notes, jobRequestAssignmentID } = data;
            let action = status === "Accepted" ? "accepted" : status === "Rejected" ? "rejected" : "updated";

            await jobRequestAssignmentService.updateAssignmentStatusAsync(
                jobRequestAssignmentID,
                status,
                rejection_reason,
            );

            // Save notes if provided
            if (notes) {
                await jobService.createNoteAsync({ job_id: data.job_id, note_text: notes, note_type: "Assignment" });
            }

            const updatedJobs = this.state.jobs.map((job) => {
                if (job.job_order_number === jobOrder) {
                    return {
                        ...job,
                        assignment: {
                            ...job.assignment,
                            status: status,
                            rejection_reason: rejection_reason,
                        },
                        grouped_notes: {
                            ...job.grouped_notes,
                            Assignment: [
                                ...(job.grouped_notes.Assignment || []),
                                {
                                    job_id: job.job_id,
                                    note_text: notes,
                                    note_type: "Assignment",
                                    note_id: jobRequestAssignmentID,
                                },
                            ],
                        },
                    };
                }
                return job;
            });

            this.setState({
                jobs: updatedJobs,
                showAlert: true,
                alertContent: {
                    title: "Change Job Assignment Request Status",
                    message: `The assignment request for job order ${jobOrder} has been ${action}`,
                    severity: "success",
                },
                rejectionAssignmentModal: null,
            });
        } catch (error) {
            // Output error message
            console.error("Failed to save assigment status:", error);
            this.setState({
                showAlert: true,
                alertContent: {
                    title: "Error",
                    message: "Failed to save assigment status",
                    severity: "error",
                },
            });
        }
    };
}

export const OnSitePage = withProtectedRoute(OnSitePageComponent);
