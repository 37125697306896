import React, { SyntheticEvent } from "react";
import {
    Notification,
    NotificationInteractionType,
    NotificationReadStatus,
    NotificationTitle,
} from "../../../models/Notification";
import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { RouteKey } from "../../../routes/Routes";

export interface NotificationCardProps {
    notification: Notification;
    onDismiss: (id: string) => void;
    onUserInteraction: (id: string, type: NotificationInteractionType) => void;
}

export const NotificationCard = React.memo(({ notification, onDismiss, onUserInteraction }: NotificationCardProps) => {
    const navigate = useNavigate();
    const icon = React.useMemo(() => {
        return notification.title === NotificationTitle.RejectedJob ? "⚠️" : null;
    }, [notification.title]);
    // const time = React.useMemo(() => {
    //     const nextDay = DateTime.fromISO(notification.delivery_time, { zone: "utc", setZone: true })
    //         .plus({ days: 1 })
    //         .setZone("local");
    //     return `This notification will expire ${
    //         nextDay.day === DateTime.now().day ? "today" : "tomorrow"
    //     } at ${nextDay.toFormat("hh:mm a")}`;
    // }, [notification.delivery_time]);
    const handleDismiss = React.useCallback(() => {
        onDismiss(notification.notification_id);
    }, [notification.notification_id, onDismiss]);
    const rejectedJobActions = React.useMemo(() => {
        const onReassign = (e: SyntheticEvent) => {
            e.stopPropagation();
            onUserInteraction(notification.notification_id, NotificationInteractionType.Reassign);
            navigate(`${RouteKey.Home}?jobId=${notification.job_id}`);
        };
        const onAddForTomorrow = (e: SyntheticEvent) => {
            e.stopPropagation();
            onUserInteraction(notification.notification_id, NotificationInteractionType.AddForTomorrow);
        };
        return (
            <div>
                <Button
                    variant="contained"
                    onClick={onReassign}
                    className="notification-action"
                    sx={{
                        minWidth: "fit-content",
                        height: "40px",
                        borderRadius: "100px",
                        gap: "8px",
                        color: "#2B2930",
                        fontWeight: 600,
                        textAlign: "center",
                        verticalAlign: "middle",
                    }}>
                    Reassign Now
                </Button>
                <Button
                    variant="contained"
                    onClick={onAddForTomorrow}
                    className="notification-action"
                    style={{ marginLeft: "2em" }}
                    sx={{
                        minWidth: "fit-content",
                        height: "40px",
                        borderRadius: "100px",
                        gap: "8px",
                        color: "#2B2930",
                        fontWeight: 600,
                        textAlign: "center",
                        verticalAlign: "middle",
                    }}>
                    Add for tomorrow
                </Button>
            </div>
        );
    }, [navigate, notification.job_id, notification.notification_id, onUserInteraction]);

    return (
        <Paper
            className={`notification-card ${
                notification.read_status === NotificationReadStatus.UNREAD ? "unread-notification" : ""
            }`}
            onClick={() => {
                onUserInteraction(notification.notification_id, NotificationInteractionType.Read);
            }}
            sx={{
                borderRadius: "10px",
                paddingTop: "10px",
                paddingRight: "16px",
                paddingBottom: "10px",
                paddingLeft: "16px",
                gap: "10px",
                height: "85px",
                mixBlendMode: "luminosity",
                backgroundColor: "#ECECEC",
            }}>
            <Stack
                width={"100%"}
                fontWeight={notification.read_status === NotificationReadStatus.UNREAD ? "bolder" : "normal"}>
                <Stack width={"100%"} direction="row" justifyContent={"space-between"}>
                    <span className="notification-card-header">
                        {icon}&nbsp;
                        <Typography
                            className="notification-card-title"
                            variant="subtitle1"
                            sx={{ flexGrow: 1 }}
                            fontWeight={
                                notification.read_status === NotificationReadStatus.UNREAD ? "bolder" : "normal"
                            }>
                            {notification.title}
                        </Typography>
                        {/* &nbsp;
                        <Tooltip className="notification-expirey-info" title={time} placement="right" arrow>
                            <InfoIcon fontSize="small" />
                        </Tooltip> */}
                    </span>
                    {notification.title === NotificationTitle.AcceptedJob ? (
                        <IconButton
                            data-testId="dismissButton"
                            className="notification-card-dismiss"
                            onClick={handleDismiss}
                            size="small"
                            sx={{
                                width: 34,
                                height: 34,
                                borderRadius: "27px",
                                gap: "10px",
                                padding: "5px",
                                background: "#FBFBFB",
                            }}>
                            <CloseIcon htmlColor="black" />
                        </IconButton>
                    ) : null}
                </Stack>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Typography
                        className="notification-card-body"
                        variant="body2"
                        fontWeight={notification.read_status === NotificationReadStatus.UNREAD ? "bolder" : "normal"}>
                        {notification.message}
                    </Typography>
                    {notification.title === NotificationTitle.RejectedJob ? rejectedJobActions : null}
                </Stack>
            </Stack>
        </Paper>
    );
});

NotificationCard.displayName = "NotificationCard";
