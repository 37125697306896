import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Container, Stack } from "@mui/material";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import TodayIcon from "@mui/icons-material/Today";

interface JobFilterSelectorsProps {
    onFilterChange: (filter: { city: string; date: string }) => void;
    defaultFormattedDate?: string;
    lastUpdated?: string | null;
}

/**
 * JobFilterSelectors component
 * @param {JobFilterSelectorsProps} props - The props
 * @param {Function} props.onFilterChange - The filter change handler
 * @returns {JSX.Element}
 */
export const JobFilterSelectors = React.memo(
    ({ onFilterChange, defaultFormattedDate, lastUpdated }: JobFilterSelectorsProps) => {
        const cities = ["Denver", "Las Vegas", "Phoenix"];
        const [selectedDate, setSelectedDate] = useState<string | null>(null);
        const [formattedDate, setFormattedDate] = useState(defaultFormattedDate ?? "");
        const [openDatePicker, setOpenDatePicker] = useState(false);
        const [activeCity, setActiveCity] = useState("Denver");

        /**
         * Handle date change
         * @param {Date} date - The selected date
         * @returns {void}
         */
        const handleDateChange = useCallback(
            (date?: Date | null) => {
                if (!date) {
                    setSelectedDate(null);
                    setFormattedDate("Select Date");
                    setOpenDatePicker(false);
                    return;
                }

                // Adjust date to local time zone
                const luxonDT = DateTime.fromJSDate(date);

                setSelectedDate(luxonDT.toFormat("yyyy-MM-dd"));
                setFormattedDate(luxonDT.toFormat("dd MMM"));
                onFilterChange({
                    city: activeCity,
                    date: luxonDT.toISO() ?? "",
                });
                setOpenDatePicker(false);
            },
            [onFilterChange, activeCity],
        );

        /**
         * Handle city change
         * @param {string} city - The selected city
         * @returns {void}
         */
        const onCityChange = useCallback(
            (city: string) => {
                setActiveCity(city);
                onFilterChange({
                    city,
                    date:
                        (selectedDate
                            ? DateTime.fromFormat(selectedDate, "yyyy-MM-dd").toISO()
                            : DateTime.now().toISO()) ?? "",
                });
            },
            [onFilterChange, selectedDate],
        );

        useEffect(() => {
            if (defaultFormattedDate) {
                setFormattedDate(defaultFormattedDate);
            }
        }, [defaultFormattedDate]);

        const timestamp = useMemo(() => {
            if (!lastUpdated) {
                return null;
            }
            const date = DateTime.fromISO(lastUpdated, {
                zone: "UTC",
            }).setZone("local");
            return `Last update at ${date.toLocaleString(DateTime.DATETIME_FULL)}`;
        }, [lastUpdated]);

        return (
            <Container maxWidth={false} disableGutters className="cities-tabs-nav">
                <Stack
                    direction="row"
                    sx={{ py: 2, pl: 0, ml: "100px", background: "#F2F2F7" }}
                    justifyContent={"space-between"}>
                    <Stack direction="row" alignItems="center" spacing={3}>
                        {/* Date Picker Button */}
                        <button
                            className="btn"
                            onClick={() => setOpenDatePicker(!openDatePicker)}
                            style={{
                                background: "#B1B1B1",
                                border: "none",
                                borderRadius: "10px",
                                color: "#FFF",
                                padding: "8px 16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            }}>
                            <TodayIcon style={{ marginRight: "8px", color: "#FFF" }} />
                            <strong>{formattedDate || "Select Date"}</strong>
                        </button>

                        {openDatePicker && (
                            <div
                                style={{
                                    position: "absolute",
                                    textAlign: "center",
                                    zIndex: 10,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    top: "65px",
                                }}>
                                <DatePicker
                                    selected={
                                        selectedDate ? DateTime.fromFormat(selectedDate, "yyyy-MM-dd").toJSDate() : null
                                    }
                                    onChange={handleDateChange}
                                    dateFormat="dd MMM yyyy"
                                    inline
                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "0 10px",
                                                alignItems: "center",
                                            }}>
                                            <button
                                                onClick={decreaseMonth}
                                                style={{ background: "none", border: "none" }}>
                                                &lt;
                                            </button>
                                            <span style={{ fontWeight: "bold", color: "#333" }}>
                                                {date.toLocaleString("default", {
                                                    month: "long",
                                                    year: "numeric",
                                                })}
                                            </span>
                                            <button
                                                onClick={increaseMonth}
                                                style={{ background: "none", border: "none" }}>
                                                &gt;
                                            </button>
                                        </div>
                                    )}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderTop: "1px solid #e0e0e0",
                                    }}></div>
                            </div>
                        )}

                        <div>
                            {cities.map((city, index) => (
                                <button
                                    key={index}
                                    className={`btn ${activeCity === city ? "active-city" : ""} ms-5`}
                                    onClick={() => onCityChange(city)}>
                                    {city}
                                </button>
                            ))}
                        </div>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <div style={{ marginRight: "50px", padding: "10px" }}>{timestamp}</div>
                    </Stack>
                </Stack>
            </Container>
        );
    },
);

JobFilterSelectors.displayName = "JobFilterSelectors";
