import { DateTime } from "luxon";
import { CSRService } from "./CSRService";
import { Job, JobFetchResponse } from "../models/Job";
import { NoteData } from "../models/NoteData";

/**
 * Service for manipulation jobs from the API
 */
export class JobService extends CSRService {
    public constructor() {
        super();
    }

    /**
     * Fetches jobs from the API
     * @param city - The city to filter jobs by
     * @param date - The date to filter jobs by
     * @returns A list of jobs
     */
    public fetchJobsAsync = async (city: string, date?: string): Promise<JobFetchResponse | undefined> => {
        const params = new URLSearchParams();
        if (city) {
            params.append("warehouse", city);
        }
        if (date) {
            params.append("date_requested", date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd") : "");
        }

        // Fetch job data
        const jobsResponse = await JobService.axiosInstance.get<JobFetchResponse>(`/api/jobs?${params.toString()}`);
        return jobsResponse.data;
    };

    public fetchJobByIdAsync = async (jobId: string): Promise<Job | undefined> => {
        try {
            const jobResponse = await JobService.axiosInstance.get<Job>(`/api/jobs/${jobId}`);
            return jobResponse.data;
        } catch (error) {
            return undefined;
        }
    };

    /**
     * Assigns a worker to a job
     * @param jobId - The ID of the job to assign the worker to
     * @param workerId - The ID of the worker to assign to the job
     */
    public assignWorkerToJobAsync = async (jobId: string, workerId: string | null): Promise<void> => {
        await JobService.axiosInstance.put(`/api/jobs/${jobId}/assign`, null, {
            params: { worker_id: workerId },
            headers: { Accept: "application/json" },
        });
    };

    /**
     * Imports new jobs from the API
     * @returns A promise that resolves when the import is complete
     */
    public importNewJobsAsync = async ({ warehouse, date }: { warehouse?: string; date?: string }): Promise<void> => {
        await JobService.axiosInstance.post("/api/import/jobs/start", null, {
            params: {
                warehouse,
                date: date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd") : undefined,
                async: false,
            },
            signal: AbortSignal.timeout(10 * 60 * 1000), // 10 minutes
            timeout: 10 * 60 * 1000, // 10 minutes
        });
    };

    // TODO: this method needs to move to a different service
    // it is not related to jobs
    /*
     * Creates a new job note
     * @param noteData - Note content and metadata
     * @returns Created note object
     */
    public createNoteAsync = async (noteData: NoteData): Promise<void> => {
        await JobService.axiosInstance.post("/api/jobnotes", noteData);
    };

    public updateJobDateRequested = async (jobId: string, newDate: string): Promise<Job | undefined> => {
        return await JobService.axiosInstance.put(`/api/jobs/${jobId}/update-requested-date`, {
            date_requested: newDate,
        });
    };
}

export const jobService = new JobService();
