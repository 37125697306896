import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // We shouldn't mix bootstrap with Material UI
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // We shouldn't mix bootstrap with Material UI
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouteDefinitions } from "./routes/RouteDefinitions";
import Watermark from "./components/Watermark";
import { NotificationProvider } from "./hoc/notifications/NotificationsContext";
import { NotificationHandler } from "./components/NotificationHandler";

const App = () => {
    const routes = useMemo(() => {
        return Object.entries(RouteDefinitions).map(([key, routeProps]) => {
            return <Route key={key} path={key} {...routeProps} />;
        });
    }, []);
    const isAdmin = localStorage.getItem("userRole") === "admin";
    return (
        <NotificationProvider>
            <Router>
                {isAdmin ? <NotificationHandler /> : null}
                <Watermark />
                <Routes>{routes}</Routes>
            </Router>
        </NotificationProvider>
    );
};

export default App;
