export enum JobEvent {
    UpdateDateRequested,
}

export class JobListeners {
    private static readonly listeners: {
        [key in JobEvent]?: () => void;
    } = {};

    public static setListener = (event: JobEvent, cb: () => void) => {
        JobListeners.listeners[event] = cb;
    };

    public static removeListener = (event: JobEvent) => {
        JobListeners.listeners[event] = undefined;
    };

    public static getListener = (event: JobEvent) => JobListeners.listeners[event];
}
