import { Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import axiosInstance from "../utils/api";

const PdfPreview = ({ jobId }) => {
    const [previewUrl, setPreviewUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleMouseEnter = async () => {
        if (!previewUrl && !loading) {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosInstance.get(`/api/job-attachments?job_id=${jobId}&preview=true`, {
                    responseType: "blob",
                });
                setPreviewUrl(URL.createObjectURL(response.data));
            } catch (error) {
                // TODO: update this to include a more robust error loading that also affects the styling of the tooltip
                console.error("Error loading preview:", error);
                if (error.response && error.response.status === 404) {
                    setError("Preview not available");
                } else {
                    setError("Unable to load preview");
                }
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        return () => {
            if (previewUrl) URL.revokeObjectURL(previewUrl);
        };
    }, [previewUrl]);

    return (
        <Tooltip
            title={
                error ? (
                    <span style={{ color: "white" }}>{error}</span>
                ) : loading ? (
                    <CircularProgress size={20} />
                ) : previewUrl ? (
                    <img src={previewUrl} alt="PDF preview" style={{ maxWidth: 200, maxHeight: 200 }} />
                ) : (
                    "Hover to load preview"
                )
            }
            arrow
            placement="right"
            onOpen={handleMouseEnter}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 21.7627H5C4.46957 21.7627 3.96086 21.552 3.58579 21.1769C3.21071 20.8018 3 20.2931 3 19.7627V5.7627C3 5.23226 3.21071 4.72355 3.58579 4.34848C3.96086 3.97341 4.46957 3.7627 5 3.7627H19C19.5304 3.7627 20.0391 3.97341 20.4142 4.34848C20.7893 4.72355 21 5.23226 21 5.7627V19.7627C21 20.2931 20.7893 20.8018 20.4142 21.1769C20.0391 21.552 19.5304 21.7627 19 21.7627H14M9 3.7627V10.7627M21 10.7627H14M3 15.7627H12"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Tooltip>
    );
};

export default PdfPreview;
