import React, { useState, useCallback, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../components/FilterComponent";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";
import PaymentsIcon from "@mui/icons-material/Payments";
import NotePreview from "../../../components/NotePreview";

// TODO: Type definitions for props
/*
interface WorkersTableProps {
    isLoading: boolean;
    workers: unknown[];
    onOpenEditWorkerModal: (workerId: number | null, isEdit: boolean) => void;
    onWorkerSelected: (worker: unknown) => void;
    }
    */

/**
 * WorkersTable component
 * @param {WorkersTableProps} props
 * @param {boolean} props.isLoading
 * @param {unknown[]} props.workers
 * @param {(workerId: number | null, isEdit: boolean) => void} props.onOpenEditWorkerModal
 * @returns JSX.Element
 */
export const WorkersTable = React.memo(({ isLoading, workers, onOpenEditWorkerModal, onWorkerSelected }) => {
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = useCallback(
        (row) => {
            setSelectedRow(row);
            onWorkerSelected(row);
        },
        [onWorkerSelected],
    );
    const filteredItems = useMemo(
        () => workers.filter((item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())),
        [workers, filterText],
    );
    const handleClear = useCallback(() => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    }, [filterText, resetPaginationToggle]);
    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "10px",
                }}>
                {/* Left Button */}
                <button
                    style={{
                        backgroundColor: "white",
                        border: "none",
                        borderRadius: "50px",
                        padding: "10px 20px",
                        marginLeft: "0px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px 1px",
                        cursor: "pointer",
                        fontWeight: "500",
                    }}
                    onClick={() => onOpenEditWorkerModal(null, false)}>
                    <AddIcon sx={{ color: "#111111B2" }} />
                    Add New Worker
                </button>

                {/* Right Filter Component */}
                <FilterComponent
                    onFilter={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </div>
        );
    }, [handleClear, filterText, onOpenEditWorkerModal]);

    // Data Table
    const columns = useMemo(
        () => [
            {
                name: (
                    <p style={{ color: "#111111B2" }}>
                        <EngineeringIcon /> Worker
                    </p>
                ),
                selector: (row) => `${row.name}`,
                sortable: true,
                cell: (row) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "80%",
                        }}>
                        <span>{row.name}</span>

                        <span
                            className="ms-2"
                            style={{
                                background: "#E5E5EA",
                                padding: "5px 7px",
                                borderRadius: "10px",
                            }}>
                            <NotePreview notes={row.notes ? row.notes : "No notes available"} />
                        </span>
                    </div>
                ),
            },
            {
                name: (
                    <p style={{ color: "#111111B2" }}>
                        <PhoneIcon />
                        Phone Number
                    </p>
                ),
                selector: (row) => row.contact_info,
                sortable: true,
                cell: (row) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            width: "100%",
                        }}>
                        <span>{row.contact_info}</span>
                    </div>
                ),
            },

            {
                name: (
                    <p style={{ color: "#111111B2" }}>
                        <RoomIcon /> Address
                    </p>
                ),
                selector: (row) => row.address,
                cell: (row) => <span>{row.address}</span>,
                sortable: true,
            },

            {
                name: (
                    <p style={{ color: "#111111B2" }}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.436 3.40972C1.19209e-07 3.90572 0 4.75772 0 6.45972V15.8537C0 17.0597 5.96046e-08 17.6627 0.315 18.1167C0.629 18.5707 1.179 18.7617 2.279 19.1427L3.573 19.5917C4.274 19.8347 4.813 20.0217 5.266 20.1417C5.562 20.2207 5.833 19.9887 5.833 19.6827V4.53272C5.82954 4.41127 5.78597 4.29439 5.70908 4.20032C5.6322 4.10625 5.52633 4.04028 5.408 4.01272C5.019 3.91372 4.551 3.75172 3.91 3.52972C2.357 2.99072 1.58 2.72172 0.99 2.99472C0.778826 3.09359 0.590251 3.23485 0.436 3.40972ZM10.62 1.74372L9.084 2.80872C8.529 3.19372 8.121 3.47672 7.774 3.67772C7.69257 3.72378 7.6245 3.79021 7.57646 3.8705C7.52843 3.95079 7.50208 4.04218 7.5 4.13572V19.1827C7.5 19.5527 7.884 19.7847 8.196 19.5847C8.531 19.3707 8.915 19.1047 9.38 18.7827L10.916 17.7177C11.471 17.3327 11.879 17.0497 12.226 16.8487C12.3074 16.8027 12.3755 16.7362 12.4235 16.6559C12.4716 16.5756 12.4979 16.4843 12.5 16.3907V1.34272C12.5 0.971716 12.116 0.740716 11.804 0.939716C11.469 1.15472 11.085 1.42072 10.62 1.74372ZM17.72 1.38272L16.427 0.934716C15.726 0.691716 15.187 0.504716 14.734 0.384716C14.438 0.305716 14.167 0.537716 14.167 0.843716V15.9937C14.1705 16.1152 14.214 16.232 14.2909 16.3261C14.3678 16.4202 14.4737 16.4862 14.592 16.5137C14.981 16.6127 15.449 16.7737 16.09 16.9967C17.643 17.5357 18.42 17.8047 19.01 17.5317C19.2212 17.4328 19.4097 17.2916 19.564 17.1167C20 16.6207 20 15.7687 20 14.0667V4.67272C20 3.46672 20 2.86272 19.685 2.40972C19.371 1.95572 18.821 1.76472 17.721 1.38372"
                                fill="#111111"
                                fillOpacity="0.7"
                            />
                        </svg>{" "}
                        Zip Code
                    </p>
                ),
                selector: (row) => row.zip_code,
                cell: (row) => <span>{row.zip_code}</span>,
                sortable: true,
            },

            {
                name: (
                    <p style={{ color: "#111111B2" }}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.2192 3.66289H14.5968V1.03889C14.5968 0.886126 14.5361 0.739618 14.4281 0.631597C14.3201 0.523576 14.1736 0.462891 14.0208 0.462891H13.5744C13.4216 0.462891 13.2751 0.523576 13.1671 0.631597C13.0591 0.739618 12.9984 0.886126 12.9984 1.03889V3.66289H10.376C10.2232 3.66289 10.0767 3.72358 9.96872 3.8316C9.8607 3.93962 9.80001 4.08613 9.80001 4.23889V13.2597H3.97761C3.65761 13.2597 3.39681 13.5205 3.39681 13.8405V17.4805C3.39681 17.8005 3.65761 18.0597 3.97761 18.0597H17.2176C17.5376 18.0597 17.7936 17.8005 17.7936 17.4821V4.23889C17.7936 4.08613 17.7329 3.93962 17.6249 3.8316C17.5169 3.72358 17.3704 3.66289 17.2176 3.66289M6.59681 16.4597H4.99681V14.8597H6.59681V16.4597ZM9.79681 16.4597H8.19681V14.8597H9.79681V16.4597ZM12.9968 16.4597H11.3968V14.8597H12.9968V16.4597ZM12.9968 13.2597H11.3968V11.6597H12.9968V13.2597ZM12.9968 10.0597H11.3968V8.45969H12.9968V10.0597ZM12.9968 6.85969H11.3968V5.25969H12.9968V6.85969ZM16.1968 16.4597H14.5968V14.8597H16.1968V16.4597ZM16.1968 13.2597H14.5968V11.6597H16.1968V13.2597ZM16.1968 10.0597H14.5968V8.45969H16.1968V10.0597ZM16.1968 6.85969H14.5968V5.25969H16.1968V6.85969ZM8.19681 1.04049C8.19702 0.964715 8.18228 0.889642 8.15343 0.819574C8.12457 0.749505 8.08218 0.685819 8.02867 0.632162C7.97517 0.578506 7.9116 0.535935 7.84161 0.506889C7.77162 0.477842 7.69659 0.46289 7.62081 0.462891H3.97601C3.82325 0.462891 3.67674 0.523576 3.56872 0.631597C3.4607 0.739618 3.40001 0.886126 3.40001 1.03889V3.66289H0.776012C0.623247 3.66289 0.47674 3.72358 0.368719 3.8316C0.260698 3.93962 0.200012 4.08613 0.200012 4.23889V17.4981C0.200012 17.8085 0.451212 18.0581 0.760012 18.0581H1.80001V11.6629H8.19841L8.19681 1.04049ZM3.40001 10.0597H1.80001V8.45969H3.40001V10.0597ZM3.40001 6.85969H1.80001V5.25969H3.40001V6.85969ZM6.60001 10.0597H5.00001V8.45969H6.60001V10.0597ZM6.60001 6.85969H5.00001V5.25969H6.60001V6.85969ZM6.60001 3.65969H5.00001V2.06289H6.60001V3.66289V3.65969Z"
                                fill="#111111"
                                fillOpacity="0.7"
                            />
                        </svg>{" "}
                        City
                    </p>
                ),
                selector: (row) => row.warehouse,
                cell: (row) => <span>{row.warehouse}</span>,
                sortable: true,
            },

            {
                name: (
                    <p style={{ color: "#111111B2" }}>
                        <PaymentsIcon /> Total
                    </p>
                ),
                selector: (row) => row.address,
                cell: (row) => <span>{row.total_paid}</span>,
                sortable: true,
            },
        ],
        [],
    );

    return isLoading ? (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
            }}>
            <h4>
                <CircularProgress size={34} /> Loading...
            </h4>
        </div>
    ) : (
        <DataTable
            columns={columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            highlightOnHover
            onRowClicked={handleRowClick}
            striped
            responsive
            conditionalRowStyles={[
                {
                    when: (row) => selectedRow && row.worker_id === selectedRow.worker_id,
                    style: {
                        backgroundColor: "#EEEEEE",
                    },
                },
            ]}
            customStyles={{
                rows: {
                    style: {
                        padding: "18px 0",
                    },
                },
            }}
        />
    );
});

WorkersTable.displayName = "WorkersTable";
