import React, { useState, useCallback, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "react-data-table-component";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FilterComponent from "../../../components/FilterComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EngineeringIcon from "@mui/icons-material/Engineering";
import NumbersIcon from "@mui/icons-material/Numbers";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import NotePreview from "../../../components/NotePreview";
import { FormControl, MenuItem, Select } from "@mui/material";

// TODO: Add Type definitions for propS

export const OnSiteTable = React.memo(
    ({ isLoading, jobs, onJobClick, onSaveAssigmentStatus, onJobsUpdated, onRejectionAssignmentRequest }) => {
        const [selectedRow, setSelectedRow] = useState(null);
        const [filterText, setFilterText] = useState("");
        const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

        const handleRowClick = useCallback(
            (row) => {
                setSelectedRow(row);
                onJobClick(row.job_id);
            },
            [onJobClick],
        );

        const handleClear = useCallback(() => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        }, [filterText, resetPaginationToggle]);

        const subHeaderComponentMemo = useMemo(() => {
            return (
                <FilterComponent
                    onFilter={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            );
        }, [handleClear]);

        const filteredItems = useMemo(() => {
            return jobs.filter((item) => {
                const searchText = filterText.toLowerCase();
                return (
                    (item.description && item.description.toLowerCase().includes(searchText)) ||
                    (item.job_order_number && item.job_order_number.toString().toLowerCase().includes(searchText)) ||
                    (item.address && item.address.toLowerCase().includes(searchText))
                );
            });
        }, [jobs, filterText]);

        const handleAssignmentChange = useCallback(
            (e, row, rejection_reason = null) => {
                const formData = {
                    status: e.target.value,
                    worker_id: row.worker_id,
                    rejection_reason: rejection_reason,
                    job_id: row.job_id,
                    jobOrder: row.job_order_number,
                    jobRequestAssignmentID: row.assignment?.assignment_id,
                };

                onSaveAssigmentStatus(formData);
            },
            [onSaveAssigmentStatus],
        );

        const handleSelectAssignmentChange = useCallback(
            (e, row) => {
                if (e.target.value === "Rejected") {
                    onRejectionAssignmentRequest(row);
                } else if (e.target.value === "Accepted") {
                    handleAssignmentChange(e, row);
                } else {
                    // TODO 2: Add logic to delete assignment object on draft status
                }
            },
            [handleAssignmentChange, onRejectionAssignmentRequest],
        );

        const columns = useMemo(
            () => [
                {
                    name: (
                        <p style={{ color: "#111111B2" }}>
                            <NumbersIcon /> Job Order
                        </p>
                    ),
                    selector: (row) => row.job_order_number,
                    sortable: true,
                    cell: (row) => <span>{row.job_order_number}</span>,
                    width: "auto",
                },

                {
                    name: (
                        <p style={{ color: "#111111B2" }}>
                            <CalendarMonthIcon /> Date Requested
                        </p>
                    ),
                    selector: (row) => row.date_requested,
                    cell: (row) => <span>{row.date_requested}</span>,
                    sortable: true,
                    width: "auto",
                },

                {
                    name: (
                        <p style={{ color: "#111111B2" }}>
                            <FactCheckOutlinedIcon /> Status
                        </p>
                    ),
                    selector: (row) => row.assignment?.status,
                    cell: (row) => (
                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: "100%" }}>
                            <FormControl sx={{ flexGrow: 1 }}>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={row.assignment?.status ? row.assignment?.status : "None"}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                        backgroundColor: "#1D1B2014",
                                        width: "auto",
                                        minWidth: "80px",
                                        maxWidth: "300px",
                                        marginRight: "10px",
                                        height: "40px",
                                        textAlign: "left",
                                    }}
                                    onChange={(e) => handleSelectAssignmentChange(e, row)}>
                                    <MenuItem value="Pending">
                                        <BorderColorIcon /> &nbsp; Draft
                                    </MenuItem>
                                    <MenuItem value="Accepted">
                                        <FactCheckOutlinedIcon /> &nbsp; Accepted
                                    </MenuItem>
                                    <MenuItem value="Rejected">
                                        <EventBusyOutlinedIcon /> &nbsp; Rejected
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    ),
                    width: "auto",
                },

                {
                    name: (
                        <p style={{ color: "#111111B2" }}>
                            <EngineeringIcon /> Worker
                        </p>
                    ),
                    selector: (row) => row.worker_name,
                    cell: (row) => <span>{row.worker_name ? row.worker_name : ""}</span>,
                    sortable: true,
                    width: "auto",
                },

                {
                    name: (
                        <p style={{ color: "#111111B2" }}>
                            <svg
                                width="18"
                                height="20"
                                viewBox="0 0 18 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.5 6.2627H12.5M5.5 9.2627H9M16 1.2627H2C1.73478 1.2627 1.48043 1.36805 1.29289 1.55559C1.10536 1.74312 1 1.99748 1 2.2627V17.2627C1 17.5279 1.10536 17.7823 1.29289 17.9698C1.48043 18.1573 1.73478 18.2627 2 18.2627H16C16.2652 18.2627 16.5196 18.1573 16.7071 17.9698C16.8946 17.7823 17 17.5279 17 17.2627V2.2627C17 1.99748 16.8946 1.74312 16.7071 1.55559C16.5196 1.36805 16.2652 1.2627 16 1.2627Z"
                                    stroke="#111111"
                                    strokeOpacity="0.7"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>{" "}
                            Notes
                        </p>
                    ),
                    selector: (row) => `${row.grouped_notes}`,
                    sortable: true,
                    cell: (row) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "80%",
                            }}>
                            <span>{row.name}</span>

                            <span
                                className="ms-2"
                                style={{ background: "#E5E5EA", padding: "5px 7px", borderRadius: "10px" }}>
                                <NotePreview
                                    notes={
                                        row.grouped_notes?.Assignment && row.grouped_notes.Assignment.length > 0
                                            ? row.grouped_notes.Assignment.map((note) => note.note_text).join("\n")
                                            : "No notes available"
                                    }
                                />
                            </span>
                        </div>
                    ),
                    width: "150px",
                },
            ],
            [handleSelectAssignmentChange],
        );

        return isLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px" }}>
                <h4>
                    <CircularProgress size={34} /> Loading...
                </h4>
            </div>
        ) : (
            <DataTable
                columns={columns}
                data={filteredItems}
                onRowClicked={handleRowClick}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover
                pointerOnHover
                striped
                responsive
                conditionalRowStyles={[
                    {
                        when: (row) => selectedRow && row.job_id === selectedRow.job_id,
                        style: {
                            backgroundColor: "#EEEEEE",
                        },
                    },
                ]}
                customStyles={{
                    rows: {
                        style: {
                            padding: "18px 0",
                        },
                    },
                }}
            />
        );
    },
);

OnSiteTable.displayName = "OnSiteTable";
