import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { isEqual } from "lodash";

interface ProtectedRouteProps {
    children?: React.ReactNode;
    requiresAdmin?: boolean;
}

const ProtectedRoute = React.memo(({ children, requiresAdmin }: ProtectedRouteProps) => {
    const authToken = localStorage.getItem("accessToken"); // Check authentication token
    const userRole = localStorage.getItem("userRole"); // Check user role
    const navigate = useNavigate();

    useEffect(() => {
        if (!authToken) {
            navigate("/login", {
                replace: true,
            });
            return;
        }
        if (requiresAdmin && authToken && userRole !== "admin") {
            navigate("/notfound", {
                replace: true,
            });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Sidebar />
            {children}
        </>
    ); // Render the protected component if authenticated
}, isEqual);

export function withProtectedRoute<T extends object>(
    WrappedComponent: React.ComponentType<T>,
    protectedRouteProps?: ProtectedRouteProps,
) {
    const ProtectedRouteComponent = (props: T) => {
        return (
            <ProtectedRoute requiresAdmin={protectedRouteProps?.requiresAdmin}>
                <WrappedComponent {...(props as T)} />
            </ProtectedRoute>
        );
    };

    return ProtectedRouteComponent;
}

export default ProtectedRoute;
