import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * JobNotesSections component
 * @param {Object} props
 * @param {Array} props.notes - The notes
 * @param {string} props.sectionTitle - The section title
 * @param {string} props.sectionWidth - The section width
 * @returns {JSX.Element}
 */
export const JobNotesSections = React.memo(({ notes, sectionTitle, sectionWidth }) => {
    return (
        <>
            {notes?.length > 0 && (
                <div className="job-notes">
                    <Box
                        sx={{
                            position: "relative",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: 2,
                            marginBottom: "10px",
                            width: sectionWidth,
                        }}>
                        {/* Label */}
                        <Typography
                            variant="caption"
                            sx={{
                                position: "absolute",
                                top: -10,
                                left: 12,
                                backgroundColor: "#f9f9f9",
                                padding: "0 4px",
                                color: "#666",
                            }}>
                            {sectionTitle}
                        </Typography>

                        {/* Notes */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1, // Spacing between notes
                            }}>
                            {notes?.map((note, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        color: note.note_type === "important" ? "red" : "black",
                                        whiteSpace: "pre-wrap",
                                        fontFamily: "Comic Sans MS",
                                    }}
                                    className="fake-data">
                                    {note.note_text}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </div>
            )}
        </>
    );
});

JobNotesSections.displayName = "JobNotesSections";
