import React, { useCallback } from "react";
import { Notification, NotificationReadStatus } from "../../models/Notification";
import { notificationService } from "../../services/NotificationService";
import { DateTime } from "luxon";
import { jobService } from "../../services/JobService";
import { JobEvent, JobListeners } from "../../utils/listeners/JobListeners";

export interface NotificationContextProps {
    notifications: Notification[];
    unreadNotificationsCount: number;
    appendNotification: (notification: Notification) => void;
    fetchNotifications: () => void;
    dismissNotification: (id: string) => void;
    markAsRead: (id: string) => void;
    fetchUnreadNotificationsCount: () => void;
    updateJobDeliveryTime: (id: string) => void;
}

export const NotificationContext = React.createContext<NotificationContextProps>({
    notifications: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchNotifications: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dismissNotification: () => {},
    unreadNotificationsCount: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    appendNotification: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchUnreadNotificationsCount: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    markAsRead: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateJobDeliveryTime: () => {},
});

export const NotificationProvider = React.memo(({ children }: { children?: React.ReactNode }) => {
    const [isFetchingNotifications, setIsFetchingNotifications] = React.useState(false);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = React.useState(0);
    const isAuthenticated = localStorage.getItem("accessToken") !== null;

    const fetchNotifications = useCallback(() => {
        if (isFetchingNotifications) {
            return;
        }
        setIsFetchingNotifications(true);
        notificationService
            .fetchAllNotificationsAsync()
            .then((notifications) => {
                setNotifications(notifications);
                setUnreadNotificationsCount(
                    notifications.filter((notification) => notification.read_status === NotificationReadStatus.UNREAD)
                        .length,
                );
            })
            .finally(() => setIsFetchingNotifications(false));
    }, [isFetchingNotifications]);

    const fetchUnreadNotificationsCount = useCallback(() => {
        notificationService.fetchUnreadNotificationCount().then((count) => {
            setUnreadNotificationsCount(count);
        });
    }, []);

    const markAsRead = useCallback(
        (id: string) => {
            notificationService.markNotificationAsRead(id).then(() => {
                setNotifications((prevNotifications) =>
                    prevNotifications.map((notification) =>
                        notification.notification_id === id
                            ? { ...notification, read_status: NotificationReadStatus.READ }
                            : notification,
                    ),
                );
                fetchUnreadNotificationsCount();
            });
        },
        [fetchUnreadNotificationsCount],
    );

    const appendNotification = useCallback((notification: Notification) => {
        setNotifications((prevNotifications) => {
            const existingNotification = prevNotifications.find(
                (n) => n.notification_id === notification.notification_id,
            );
            if (existingNotification) {
                return prevNotifications.map((n) =>
                    n.notification_id === notification.notification_id ? notification : n,
                );
            }
            return [...prevNotifications, notification];
        });
    }, []);

    const dismissNotification = useCallback(
        (id: string) => {
            let removedNotification: Notification | undefined;
            setNotifications((prevNotifications) => {
                removedNotification = prevNotifications.find((notification) => notification.notification_id === id);
                notificationService.deleteNotification(id).then((didDelete) => {
                    if (!didDelete && removedNotification) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        setNotifications((prev) => [...prev, removedNotification!]);
                    }
                });
                return prevNotifications.filter((notification) => notification.notification_id !== id);
            });
            markAsRead(id);
        },
        [markAsRead],
    );

    const updateJobDeliveryTime = useCallback(
        (id: string) => {
            const notification = notifications.find((n) => n.notification_id === id);
            if (!notification || !notification.job_id) {
                return;
            }
            jobService
                .fetchJobByIdAsync(notification.job_id)
                .then((job) => {
                    if (!job) {
                        return;
                    }
                    const updatedTime = DateTime.fromISO(job.date_requested, { zone: "utc", setZone: true })
                        .plus({ day: 1 })
                        .toISO();
                    if (!updatedTime) {
                        return;
                    }
                    jobService
                        .updateJobDateRequested(job.job_id, updatedTime)
                        .then(() => {
                            const update = JobListeners.getListener(JobEvent.UpdateDateRequested);
                            if (update) {
                                update();
                            }
                        })
                        .catch(console.error)
                        .finally(() => {
                            fetchNotifications();
                        });
                })
                .catch(fetchNotifications);
        },
        [fetchNotifications, notifications],
    );

    React.useEffect(() => {
        if (isAuthenticated) {
            fetchUnreadNotificationsCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                fetchNotifications,
                dismissNotification,
                unreadNotificationsCount,
                appendNotification,
                fetchUnreadNotificationsCount,
                markAsRead,
                updateJobDeliveryTime,
            }}>
            {children}
        </NotificationContext.Provider>
    );
});
